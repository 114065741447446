import React, { useState } from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import marker from '../images/icons/map-marker.svg';
import { colors, device } from './layout/GlobalStyles';
import greenWave from '../images/icons/green-wave.svg';
import { ThankYou } from './ThankYou';

const StyledContactForm = styled.div`
    .bridge {
        width: 100vw;
        position: absolute;
        bottom: 0em;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        ul {
            padding: 0em;
            list-style: none;
            li {
                margin: 1em 0em;
                font-weight: 600;
            }
        }
    }

    .trains {
        display: flex;
        margin: 1em 0em;
        span {
            margin-right: 1em;
            color: ${colors.white};
            font-weight: 700;
            font-size: 18px;

            border-radius: 100px;
        }
        .g-train {
            padding: 0.7em 1.1em;
            background: #1a822b;
        }

        .e-train {
            padding: 0.7em 1.2em;
            background: #2850ad;
        }
    }
    h5 {
        letter-spacing: 0.75px;
    }
    background: ${colors.lightGreen};
    .panel {
        padding: 2em;
        border-radius: 41px;
        margin: 2em 1em 0em;
        background: ${colors.white};
        display: grid;
        column-gap: 8px;
        row-gap: 24px;
        grid-template-columns: 1fr;
    }

    textarea {
        border-radius: 8px;
        margin: 0em 0em 3em;
        border: none;
        background: white;
        padding: 1em;
        width: 300px;
        height: 100px;
    }
    form {
        z-index: 400;
        .input {
            position: relative;
            display: flex;
            margin: 2.5em 0em;
            align-items: center;
            .spec {
                left: 0.5em;
                width: auto;
                min-height: 20px;
                max-height: 30px;
            }
            img,
            input {
                position: absolute;
            }
            input {
                text-indent: 50px;
                width: 230px;
                border: none;
                padding: 1.2em 0.75em;
                border-radius: 8px;
            }
        }
    }
    @media ${device.tablet} {
        .flex-inputs {
            width: 300px;
            justify-content: space-between;
            display: flex;
        }
        .left-column {
            display: flex;
            flex-direction: column;
            padding-left: 0em;
            align-items: flex-start;
        }
        .panel {
            max-width: 1320px;
            margin: 0 auto;
        }
    }
    @media ${device.laptopL} {
        .flex-inputs {
            width: 300px;
            justify-content: space-between;
            display: flex;
        }
        .left-column {
            display: flex;
            flex-direction: column;
            padding-left: 0em;
            align-items: flex-start;
        }
        .right-column {
            display: block;
            margin-top: 4em;
            margin-right: 3em;
        }
        .panel {
            max-width: 1220px;
            margin: 0 auto;
            grid-template-columns: 1fr 1fr;
        }
    }
`;

const StyledButton = styled(Button)`
    cursor: pointer;
`;

export const ContactForm: React.FC = () => {
    const [submitted, setSubmitted] = useState(false);
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitted(true);
        setSubmitState('submitting');
        const data = Object.fromEntries(new FormData(e.target));
        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        setSubmitState('submitted');
        e.target.reset();
    };

    return (
        <div>
            <StyledContactForm>
                <img
                    style={{
                        position: 'relative',
                        transform: 'scaleY(-1)',
                        width: '100vw',
                        height: 'auto',
                        background: `${colors.white}`,
                    }}
                    className="wave"
                    src={greenWave}
                    alt="A green wave"
                />
                <div className="panel">
                    <div className="left-column">
                        {!submitted ? (
                            <>
                                <h2>Contact Us</h2>
                                <form onSubmit={onSubmit}>
                                    <div className="flex-inputs">
                                        <div>
                                            <div className="input">
                                                <input
                                                    type="text"
                                                    required
                                                    name="pet"
                                                    placeholder="Pets' Name"
                                                />
                                                <StaticImage
                                                    placeholder="none"
                                                    className="spec"
                                                    src="../images/icons/dog.svg"
                                                    alt="a little dog"
                                                />
                                            </div>
                                            <div className="input">
                                                <input
                                                    type="text"
                                                    required
                                                    name="humansName"
                                                    placeholder="Human's Name"
                                                />
                                                <StaticImage
                                                    placeholder="none"
                                                    className="spec"
                                                    src="../images/icons/human.svg"
                                                    alt="avatar person"
                                                />
                                            </div>

                                            <div className="input">
                                                <input
                                                    type="text"
                                                    required
                                                    name="phone"
                                                    placeholder="Phone"
                                                />
                                                <StaticImage
                                                    placeholder="none"
                                                    className="spec"
                                                    src="../images/icons/phone.svg"
                                                    alt="a mobile phone"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div className="input">
                                                <input
                                                    name="petType"
                                                    type="text"
                                                    placeholder="Pet Type"
                                                />
                                                <StaticImage
                                                    placeholder="none"
                                                    className="spec"
                                                    style={{ fill: 'red' }}
                                                    src="../images/icons/pet-type.svg"
                                                    alt="little animal head"
                                                />
                                            </div>
                                            <div className="input">
                                                <input
                                                    required
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                                <StaticImage
                                                    placeholder="none"
                                                    className="spec"
                                                    style={{ fill: 'black' }}
                                                    src="../images/icons/email-form.svg"
                                                    alt="email envelope"
                                                />
                                            </div>
                                            <div className="input">
                                                <input
                                                    type="text"
                                                    required
                                                    name="previous-vet"
                                                    placeholder="Previous Vet"
                                                />
                                                <StaticImage
                                                    placeholder="none"
                                                    className="spec"
                                                    style={{ fill: 'red' }}
                                                    src="../images/icons/pet-type.svg"
                                                    alt="little animal head"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <textarea
                                            name="comments"
                                            placeholder="Add any comments for our
                        staff or doctors"
                                        />
                                    </div>

                                    {submitState === 'submitted' ? (
                                        <>
                                            <h3>
                                                Message sent! Somebody from our office will be in
                                                touch shortly.
                                            </h3>
                                        </>
                                    ) : (
                                        <StyledButton
                                            disabled={submitState === 'submitting'}
                                            color="blue"
                                            type="submit"
                                        >
                                            {submitState === 'submitting'
                                                ? 'Sending message...'
                                                : 'Send Message'}
                                        </StyledButton>
                                    )}
                                </form>
                            </>
                        ) : (
                            <ThankYou />
                        )}
                    </div>
                    <div className="right-column">
                        <img src={marker} alt="map marker" />
                        <p>The Otto Building, 211 McGuinness Blvd, Suite 6, Brooklyn, NY 11222</p>
                        <div className="trains">
                            <span className="g-train train">G</span>
                            <span className="e-train train">E</span>
                        </div>
                        <div className="black-line"></div>
                        <h2>HOURS</h2>
                        <div className="flex">
                            <ul>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>
                                    Saturday <br />
                                    &nbsp;
                                </li>
                                <li style={{ opacity: '.5' }}>Sunday</li>
                            </ul>
                            <ul>
                                <li>9:00 AM to 7:00 PM</li>
                                <li>9:00 AM to 7:00 PM</li>
                                <li>9:00 AM to 7:00 PM</li>
                                <li>9:00 AM to 3:00 PM</li>
                                <li>9:00 AM to 7:00 PM</li>
                                <li>
                                    8:00 AM to 12:00 PM <br />
                                    (every other Saturday)
                                </li>
                                <li style={{ opacity: '.5' }}>Closed</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </StyledContactForm>
        </div>
    );
};
