import styled from '@emotion/styled';
import { Button } from './layout/StyledComponents';
import { colors, device } from './layout/GlobalStyles';
import marker from '../images/icons/map-marker.svg';
import greenWave from '../images/icons/green-wave.svg';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHours = styled.section`
    background: ${colors.lightGreen};
    position: relative;
    button {
        z-index: 1;
        position: relative;
    }
    .bridge {
        z-index: 0;
        width: 100vw;
        position: absolute;
        bottom: 0em;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        ul {
            padding: 0em;
            list-style: none;
            li {
                margin: 1em 0em;
                font-weight: 600;
            }
        }
    }
    .black-line {
        width: 90vw;
        height: 2px;
        margin: 1.5em 0em 2em;
        background-color: black;
    }
    .trains {
        display: flex;
        margin: 1em 0em;
        span {
            margin-right: 1em;
            color: ${colors.white};
            font-weight: 700;
            font-size: 18px;

            border-radius: 100px;
        }
        .g-train {
            padding: 0.7em 1.1em;
            background: #1a822b;
        }
        .e-train {
            padding: 0.7em 1.2em;
            background: #2850ad;
        }
    }
    h5 {
        letter-spacing: 0.75px;
    }
    .columns {
        padding: 2em 1em;
        .left-column {
            button {
                margin: 2em auto 2em;
            }
        }
        h2 {
            font-size: 36px;
            line-height: 48px;
            margin: 0em 0em 0.2em;
        }
        .right-column {
            img {
                margin: 1em 0em;
            }
            p {
                font-weight: 600;
            }
        }
    }
    @media ${device.tablet} {
        padding: 0em 3em;
        p {
            font-size: 18px;
            max-width: 480px;
        }
        .left-column {
            p {
                margin-bottom: 2em;
            }
            h2 {
                font-size: 48px;
                line-height: 48px;
                line-height: 66px;
                margin: 0.5em 0em;
            }
        }
        .flex {
            padding-bottom: 6em;
            justify-content: space-between;
            width: 75%;
            ul:last-of-type {
                text-align: right;
            }
        }
        .black-line {
            width: 600px;
        }
        .columns {
            display: flex;
            .right-column {
                width: 40vw;
            }
        }
    }
    @media ${device.laptopL} {
        display: flex;
        justify-content: center;
        h2 {
            width: 85%;
        }
        .flex {
            padding-bottom: 7em;
        }
        .black-line {
            width: 660px;
        }
        .columns {
            .right-column {
                width: 50vw;
            }
        }
        .left-column {
            p {
                margin-bottom: 3em;
            }
        }
    }
    @media ${device.desktop} {
        h2 {
            width: 75%;
        }
        .black-line {
            width: 760px;
        }
        .columns {
            max-width: 1600px;
            .left-column {
                p {
                    font-size: 22px;
                    line-height: 32px;
                    max-width: 600px;
                }
            }
            h2 {
                line-height: 67px;
                font-size: 57px;

                margin: 0.5em 0em;
            }
        }
    }
`;

const StyledButton = styled(Button)`
    cursor: pointer;
`;

interface Props {
    waves?: boolean;
}

export const Hours: React.FC<Props> = ({ waves }) => {
    return (
        <div>
            {waves && (
                <img
                    style={{
                        position: 'relative',
                        top: '.5em',
                        transform: 'scaleY(-1)',
                        width: '100vw',
                        height: 'auto',
                    }}
                    className="wave"
                    src={greenWave}
                    alt="A green wave"
                />
            )}
            <StyledHours>
                <div className="columns">
                    <div className="left-column">
                        <h2>Greenpoint Veterinary Care</h2>
                        <p>
                            All new, state of the art veterinary practice coming to Greenpoint
                            Brooklyn. Conveniently located next to the Otto building on McGuinness
                            Blvd.
                        </p>
                        <a
                            target="_blank"
                            href="mailto:Info@greenpointvetcare.com"
                            rel="noreferrer"
                        >
                            <StyledButton wide>Email Us</StyledButton>
                        </a>
                    </div>
                    <div className="right-column">
                        <img src={marker} alt="map marker" />
                        <p>The Otto Building, 211 McGuinness Blvd, Suite 6, Brooklyn, NY 11222</p>
                        <div className="trains">
                            <span className="g-train train">G</span>
                            <span className="e-train train">E</span>
                        </div>
                        <div className="black-line"></div>
                        <h2>HOURS</h2>
                        <div className="flex">
                            <ul>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>
                                    Saturday <br />
                                    &nbsp;
                                </li>
                                <li style={{ opacity: '.5' }}>Sunday</li>
                            </ul>
                            <ul>
                                <li>9:00 AM to 7:00 PM</li>
                                <li>9:00 AM to 7:00 PM</li>
                                <li>9:00 AM to 7:00 PM</li>
                                <li>9:00 AM to 3:00 PM</li>
                                <li>9:00 AM to 7:00 PM</li>
                                <li>
                                    8:00 AM to 12:00 PM <br />
                                    (every other Saturday)
                                </li>
                                <li style={{ opacity: '.5' }}>Closed</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <StaticImage
                    placeholder="blurred"
                    src="../images/icons/brooklyn-bridge.svg"
                    className="bridge"
                    alt="brooklyn bridge"
                />
            </StyledHours>
        </div>
    );
};
