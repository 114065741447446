import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors, device } from './layout/GlobalStyles';
import bone from '../images/icons/dog-bone.svg';
import { Button } from './layout/StyledComponents';

const StyledHomeServices = styled.section`
    padding: 7.5em 1em;
    h2 {
        font-size: 36px;
    }
    p {
        margin: 1em 0em;
    }
    ul {
        padding: 0em;
        list-style: none;
        margin-bottom: 2em;
        span {
            font-weight: 600;
        }
        li {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            padding-top: 1em;
            align-items: center;
            img {
                margin-right: 0.5em;
                background: ${colors.lightGreen};
                padding: 0.5em;
                border-radius: 100%;
            }
            margin: 1em 0em;
        }
        div {
            border-bottom: 2px solid #37a3a5;
        }
    }
    button {
        cursor: pointer;
    }
    .more {
        margin-bottom: 2em;
        font-weight: 600;
    }
    @media ${device.tablet} {
        padding: 3.5em 1em;
        .header {
            display: flex;
            justify-content: space-between;
            p {
                max-width: 33%;
                align-self: flex-end;
                text-align: right;
            }
        }
        max-width: 1000px;
        margin: 0 auto;

        .flex {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        button {
        }
        ul {
            img {
                width: 40px;
            }
            li {
                font-size: 22px;
            }
            margin: 2em 0em;
            div {
                position: relative;
                span {
                    position: absolute;
                    right: 1em;
                    bottom: 2em;
                }
                align-items: center;
                display: flex;
            }
        }
    }
    @media ${device.laptop} {
        .header {
            h2 {
                font-size: 48px;
            }
        }
    }
    @media ${device.laptopL} {
        ul {
            margin: 2em;
            li {
                font-size: 20px;
                img {
                    width: 45px;
                    margin-right: 0.8em;
                }
            }
        }
    }
    @media ${device.desktop} {
        ul {
            margin: 2em;
            li {
                font-size: 20px;
                img {
                    width: 45px;
                    margin-right: 0.8em;
                }
            }
        }
        .header {
            h2 {
                font-size: 56px;
            }
            p {
                max-width: 40%;
                font-size: 20px;
            }
        }
    }
`;

interface Props {
    homepage?: boolean;
}

export const HomeServices: React.FC<Props> = ({ homepage }) => {
    return (
        <StyledHomeServices>
            <div className="header">
                <h2>{homepage ? 'Services' : 'Other Services'}</h2>
                <p>We are at your service with all our might, and all your personalities.</p>
            </div>
            <ul>
                <Link to="/services/wellness-visit/">
                    <div>
                        <li>
                            <img src={bone} alt="dog bone" />
                            Wellness Visits
                        </li>
                        <span>Learn More</span>
                    </div>
                </Link>
                <Link to="/services/dental/">
                    <div>
                        <li>
                            <img src={bone} alt="dog bone" />
                            Pet Dentistry
                        </li>
                        <span>Learn More</span>
                    </div>
                </Link>
                <Link to="/services/pet-travel-certification/">
                    <div>
                        <li>
                            <img src={bone} alt="dog bone" />
                            Pet Travel Certificates
                        </li>
                        <span>Learn More</span>
                    </div>
                </Link>
                <Link to="/services/spay-neuter/">
                    <div>
                        <li>
                            <img src={bone} alt="dog bone" />
                            Spay & Neuter
                        </li>
                        <span>Learn More</span>
                    </div>
                </Link>
            </ul>
            <div className="flex">
                <Link to="/services/">
                    <Button>See all Services</Button>
                </Link>
            </div>
        </StyledHomeServices>
    );
};
