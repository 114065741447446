import * as React from 'react';
import { graphql } from 'gatsby';
import { Hero } from '../components/Hero';
import { ServicesList } from '../components/ServicesList';
import { Hours } from '../components/Hours';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '../components/Seo';

interface Props {
    allMdx: {
        edges: [];
    };
}

const IndexPage: Page<Props> = ({ data }) => {
    const allMdx = data.allMdx.edges;
    return (
        <>
            <DefaultLayout>
                <Seo
                    title="Services - Greenpoint Vet Care, BK"
                    description="Offering a wide array of veterinary services location in Greenpoint, BK"
                />
                <Hero title="Services" />
                <ServicesList services={allMdx} />
                <Hours />
            </DefaultLayout>
        </>
    );
};

export const query = graphql`
    query {
        allMdx {
            edges {
                node {
                    id
                    frontmatter {
                        listTitle
                        seoDescription
                        seoTitle
                        slug
                        title
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: NONE
                                    quality: 100
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;
