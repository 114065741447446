import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Template, Button } from './layout/StyledComponents';
import { colors, device } from './layout/GlobalStyles';

const StyledTemplate = styled(Template)``;

const StyledServicesList = styled.section`
    background: ${colors.white};
    padding: 0.5em 1em;
    .desktop-button {
        display: none;
    }
    .mobile-button {
        margin: 1em 0em;
    }
    button {
        margin-top: -2em;
    }
    .item {
        border-bottom: 2px solid ${colors.aqua};
        padding-bottom: 1em;
        .plus {
            position: relative;
            bottom: 0.1em;
        }
        span {
            color: ${colors.aqua};
            font-weight: 600;
            margin: 1em 1em 0em 0em;
        }
        p {
            margin-bottom: 1em;
            font-size: 18px;
            line-height: 38px;
            font-weight: 600;
            letter-spacing: 0.75px;
        }
        margin: 3em 0em;
        .image-container {
            position: relative;
            margin: 1em 0em;
            margin-bottom: 2em;
        }
        .bottom-layer {
            top: 3em;
            opacity: 0.9;
            left: 1em;
            filter: blur(35px);
            position: absolute;
            height: 90%;
        }
        img {
            border-radius: 33px;
        }
        h2 {
            margin: 0.5em 0em;
            font-size: 36px;
            letter-spacing: 1px;
        }
    }
    @media ${device.laptop} {
        .desktop-button {
            display: block;
            margin: 2em 0em 1em 0em;
        }
        .mobile-button {
            display: none;
        }
        span {
            padding-bottom: 1em;
        }
        .line {
            height: 2px;
            width: 400px;
            margin-top: 2em;
            background: ${colors.aqua};
        }
        .flex {
            flex-direction: row;
        }
        .reverse {
            flex-direction: row-reverse;
        }
        .item {
            border-bottom: none;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .featuredImage {
                width: 500px;
            }
            .text {
                max-width: 490px;
            }
        }
    }
    @media ${device.laptopL} {
        max-width: 1280px;
        margin: 0 auto;
        .text {
            p {
                font-size: 18px;
            }
            h2 {
                font-size: 48px;
            }
        }
    }
    @media ${device.desktop} {
        .item {
            .featuredImage {
                width: 600px;
            }
            .text {
                h2 {
                    font-size: 54px;
                }
                p {
                    font-size: 21px;
                }
                span {
                    font-size: 21px;
                }
                max-width: 600px;
            }
        }
        max-width: 1480px;
        margin: 0 auto;
    }
`;

interface Services {
    node: {
        id: number;
        frontmatter: {
            image: {
                childImageSharp: {
                    gatsbyImageData: any;
                };
            };
            listTitle: string;
            altTag: string;
            seoDescription: string;
            seoTitle: string;
            slug: string;
            title: string;
        };
    };
}

interface Props {
    services: Services[];
}

export const ServicesList: React.FC<Props> = ({ services }) => {
    return (
        <div>
            <StyledServicesList>
                {services.map((service, index) => (
                    <section key={service.node.frontmatter.title}>
                        <div className={index % 2 === 0 ? 'flex item' : 'item reverse'}>
                            <div className="image-container">
                                <GatsbyImage
                                    className="featuredImage bottom-layer"
                                    image={
                                        service.node.frontmatter.image.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt="bottom layer color"
                                />
                                <GatsbyImage
                                    className="featuredImage"
                                    image={
                                        service.node.frontmatter.image.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt={service.node.frontmatter.altTag}
                                />
                            </div>
                            <div className="text">
                                <h2>{service.node.frontmatter.title}</h2>
                                <p>{service.node.frontmatter.listTitle}</p>
                                <Link to={`/services/${service.node.frontmatter.slug}`}>
                                    <span>Learn More</span>{' '}
                                    <StaticImage
                                        className="plus"
                                        placeholder="none"
                                        src="../images/icons/aqua-plus.svg"
                                        alt="plus sign"
                                    />
                                </Link>
                                <br />
                                <div className="line"></div>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://greenpointvet.use2.ezyvet.com/external/portal/calendar"
                                >
                                    <Button className="desktop-button">Book Now</Button>
                                </a>
                            </div>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://greenpointvet.use2.ezyvet.com/external/portal/calendar"
                            >
                                <Button className="mobile-button"> Book Now</Button>
                            </a>
                        </div>
                    </section>
                ))}
            </StyledServicesList>
            <StyledTemplate>
                <div className="second-image-container">
                    <div className="pic-text">
                        <h4 style={{ marginBottom: '1em' }}>
                            Don’t see what you need? Chances are we do it.
                        </h4>
                        <span>Call us! 917-970-0950</span>
                    </div>
                    <StaticImage
                        placeholder="none"
                        className="second-bottom-layer"
                        src="../images/cat-finger.jpeg"
                        alt="cat reaching to human"
                    />
                    <StaticImage
                        placeholder="none"
                        className="second-featured-image"
                        src="../images/cat-finger.jpeg"
                        alt="cat reaching to human"
                    />
                </div>
            </StyledTemplate>
        </div>
    );
};
