import * as React from 'react';
import { HomeHero } from '../components/HomeHero';
import { HomeList } from '../components/HomeList';
import { Hours } from '../components/Hours';
import { HomeSlider } from '../components/HomeSlider';
import { Video } from '../components/PlayVideo';
import { HomeServices } from '../components/HomeServices';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '../components/Seo';
import { Wellness } from '../components/Wellness';
import { ModalBlock } from '../components/Modal';
import { Reviews } from '../components/layout/Reviews';

// markup
const Home: Page = () => {
    return (
        <>
            <Seo
                title="Greenpoint Vet Care, BK | Veterinary Care to Veterinary Care in Brooklyn, NY | Greenpoint Vet Care"
                description="Your pet deserves the best and we believe so do you. We provide top class services for all kinds of pets."
            />
            <DefaultLayout faq>
                {/* <ModalBlock /> */}
                <HomeHero />
                <Video />
                <HomeList />
                <Hours />
                <HomeSlider />
                <HomeServices homepage />
                <Reviews />
            </DefaultLayout>
        </>
    );
};

export default Home;
