import styled from '@emotion/styled';
import React from 'react';
import { Button } from './layout/StyledComponents';
import { colors, device } from './layout/GlobalStyles';
import marker from '../images/icons/map-marker.svg';
import greenWave from '../images/icons/green-wave.svg';
import { StaticImage } from 'gatsby-plugin-image';

const StyledVideo = styled.section`
    .video-container {
        margin: 0em 0.75em;
        padding: 1em;
        background: ${colors.lightGreen};
        border-radius: 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            padding: 1em 0em;
        }
    }
    @media ${device.laptop} {
        .logo-pets {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            position: relative;
            bottom: 8em;
        }

        .video-container {
            position: relative;
            max-width: 960px;
            margin: 10em auto 3em;
            height: 540px;
            padding: 1em 1em;
            background: ${colors.extraLightGreen};
        }
        .play-button {
            position: absolute;
            left: 3em;
            bottom: 1em;
        }
    }
`;

interface Props {
    waves?: boolean;
}

export const Video: React.FC<Props> = () => {
    return (
        <>
            <StyledVideo>
                <div className="video-container">
                    <div className="logo-pets">
                        <StaticImage
                            quality={100}
                            className="video-pets"
                            src="../images/video-pets.png"
                            alt="group of pets"
                            placeholder="none"
                        />
                        <StaticImage
                            quality={100}
                            className="video-logo"
                            src="../images/video-logo.png"
                            alt="greenpoint vet care logo"
                            placeholder="none"
                        />
                    </div>
                    <a
                        href="https://www.youtube.com/watch?v=ChKsot2eEok"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <div className="play-button" style={{ cursor: 'pointer' }}>
                            <StaticImage
                                quality={100}
                                src="../images/video-button.png"
                                alt="greenpoint vet care logo"
                                placeholder="none"
                            />
                        </div>
                    </a>
                </div>
            </StyledVideo>
        </>
    );
};
