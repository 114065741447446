import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledReviews = styled.div`
    position: relative;
    margin-top: 84px;
    .graphic {
        display: none;
    }
    .google {
        position: absolute;
        bottom: 1em;
    }
    section {
        padding: 2em 0em 2em 0em;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
    }
    .item {
        z-index: 1;

        min-width: 320px;
        position: relative;
        background: rgba(250, 250, 250, 0.8);
        box-shadow: -8px -8px 50px #ffffff, 8px 8px 32px rgba(0, 0, 0, 0.2);
        border-radius: 32px;
        margin: 1em 0.5em;
        padding: 1em;
    }
    .stars {
        margin: 0.5em 0em;
    }
    p {
        margin-bottom: 4em;
    }
    padding: 1em;
    h2 {
        font-size: 36px;
        line-height: 42px;
        color: ${colors.textGreen};
    }
    @media ${device.tablet} {
        section {
            padding: 2em 0em 14em 0em;
        }
        padding-left: 4em;
        .graphic {
            z-index: 0;
            right: 2em;
            width: 640px;
            position: absolute;
            bottom: 0em;
            display: block;
        }
    }
    @media ${device.laptopL} {
        h2 {
            line-height: 68px;
            font-size: 59px;
        }
    }
`;

const reviews = [
    {
        name: 'Tara S.',
        review: `Being a new cat mom, I was a bit anxious about my kitty's first vet visit.  My fears were quickly allayed when I met Dr. Stein and his staff. They graciously made a house call to meet and examine my kitten.  Dr. Stein is warm and great with animals and knowledgeable.`,
    },
    {
        name: 'Tracy M.',
        review: `What a wonderful experience I had with both of my pets. Dr Stein is extremely caring and knowledgeable. The Doctor explained everything in a clear manner, and made sure I understood everything before I left the office.`,
    },
    {
        name: 'Alexis L.',
        review: `After adopting our new cat last month, we came for an ear (mite) check and general status check. Dr Stein and his staff answered all our questions thoroughly and patiently, and equally thorough and caring in the examination.`,
    },

    {
        name: 'Crystsl GH.',
        review: 'Dr. Stein and staff are amazing, caring and thoughtful for both owners and pets. The offices are clean and spacious. You can definitely see that everyone there gives realistic advice and genuinely wants the best for their little patients.',
    },
    {
        name: 'Makika D.',
        review: `Dr. Stein and his staff are extremely kind and knowledgeable. They make an accurate diagnosis of my cat. Dr. Stein explains everything I need to know about my cat's condition and kindly answers all my questions in emails as well, 100% trust in his advice. They really do go above and beyond for their patients`,
    },
];
export const Reviews: React.FC = () => {
    return (
        <StyledReviews>
            <h2>Everyone Loves Greenpoint Veterinary Care</h2>
            <p style={{ margin: '1em 0em', fontWeight: '600', color: `${colors.textGreen}` }}>
                Scroll through to read reviews:
            </p>
            <section>
                {reviews.map(review => (
                    <div key={review.name} className="item">
                        <span>{review.name}</span>
                        <div className="stars">
                            <StaticImage
                                placeholder="none"
                                className="star"
                                src="../../images/icons/star.svg"
                                alt="yellow star"
                            />
                            <StaticImage
                                placeholder="none"
                                className="star"
                                src="../../images/icons/star.svg"
                                alt="yellow star"
                            />
                            <StaticImage
                                placeholder="none"
                                className="star"
                                src="../../images/icons/star.svg"
                                alt="yellow star"
                            />
                            <StaticImage
                                placeholder="none"
                                className="star"
                                src="../../images/icons/star.svg"
                                alt="yellow star"
                            />
                            <StaticImage
                                placeholder="none"
                                className="star"
                                src="../../images/icons/star.svg"
                                alt="yellow star"
                            />
                        </div>
                        <p>{review.review}</p>
                        <a
                            href="https://www.google.com/maps/place/Greenpoint+Veterinary+Care/@40.7296014,-73.9535709,17z/data=!4m7!3m6!1s0x89c25943adfcac41:0xd98626d50d991bde!8m2!3d40.7295974!4d-73.9513822!9m1!1b1"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <StaticImage
                                className="google"
                                placeholder="none"
                                src="../../images/icons/google.svg"
                                alt="google logo"
                            />
                        </a>
                    </div>
                ))}
            </section>

            <StaticImage
                className="graphic"
                placeholder="none"
                src="../../images/colored-graphic.png"
                alt="person standing by computer"
            />
        </StyledReviews>
    );
};
