import * as React from 'react';
//components
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Template } from '../components/layout/StyledComponents';
import { Hero } from '../components/Hero';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { colors, device } from '../components/layout/GlobalStyles';
import { Seo } from '../components/Seo';

const StyledTemplate = styled(Template)``;

interface Props {
    data: {
        mdx: {
            frontmatter: {
                title: string;
                heroTitle: string;
                seoTitle: string;
                seoDescription: string;
                altTag: string;
                image: {
                    childImageSharp: {
                        gatsbyImageData: string;
                    };
                };
            };
            body: string;
        };
    };
}

const StyledAgainTemplate = styled(StyledTemplate)`
    .second-doctor-container {
        display: flex;
        flex-direction: column-reverse;
        .second-title {
            display: none;
        }
        .nip {
            display: block;
        }
    }
    .doctor-pics {
        position: relative;
        div {
            margin: 1em 0em;
        }
        img {
            border-radius: 33px;
        }
        .underlayer {
            position: absolute;
            filter: blur(50px);
            border-radius: 33px;
        }
    }
    .top-picture {
        .paragraph {
            max-width: 350px;
        }
        h3 {
            display: none;
        }
        span {
            display: none;
        }
    }
    .pics {
        border-radius: 100px;
        width: 190px;
        height: 190px;
    }
    .body {
        padding-bottom: 2em;
        border-bottom: 2px solid #042950;
        margin-bottom: 3em;
    }
    @media ${device.tablet} {
        padding-bottom: 8em;
        .nip {
            display: none;
        }
        .doctor-pics-container {
            display: flex;
            justify-content: center;
        }
        .second {
            position: relative;
            top: 4em;
        }
        .doctor-pics {
            position: relative;
            .doctor-pic {
                margin: 0em 2em;
                width: 300px;
                height: 433px;
            }
        }
        .second-doctor-container {
            display: flex;
            flex-direction: row;
            top: 5em;
            position: relative;
            padding-bottom: 6em;

            .second-title {
                margin-left: 2em;
                display: block;

                h3 {
                    font-size: 36px;
                }
                span {
                    font-size: 21px;
                }
                h3,
                span {
                    text-decoration: none;
                    text-align: center;
                }
                text-decoration: none;
                position: absolute;
                bottom: 0em;
            }
            .nip {
                display: none;
            }
            background: ${colors.lightGreen};
            border-radius: 64px;
            align-items: center;
            max-height: 671px;
            justify-content: space-around;
            display: flex;
            margin: 0 auto;
            max-width: 940px;
            .paragraph {
                max-width: 40%;
            }
        }
        .pic-featured-image {
            position: relative;
        }
        .pic-bottom-layer {
            left: 0;
            top: 0;
            position: absolute;
        }
        .pic-flex {
            background: ${colors.lightGreen};
            display: flex;
        }
        .body {
            padding-bottom: 3em;
            border-bottom: 2px solid #042950;
            margin-bottom: 6em;
        }
        .top-picture {
            display: flex;
            justify-content: center;
            align-items: center;
            h3 {
                display: block;
                font-size: 36px;
                margin-left: 3em;
                span {
                    display: block;
                    font-size: 22px;
                }
            }
        }
    }
    @media ${device.laptopL} {
        .doctor-pics {
            position: relative;
            .doctor-pic {
                margin: 0em 2em;
                width: 400px;
                height: 533px;
            }
        }
    }
`;

const AboutUs: Page<Props> = ({ data }) => {
    return (
        <>
            <DefaultLayout>
                <Seo
                    title="About Us - Greenpoint BK, Vet Care"
                    description="Meet our wonderful and passionate staff working at Greenpoint Vet Care"
                />
                <Hero title="About Us" />
                <StyledAgainTemplate>
                    <div className="a top-picture">
                        <div className="image-container">
                            <StaticImage
                                placeholder="none"
                                className="about-bottom-layer"
                                src="../images/dr-stein.jpeg"
                                alt="blurry pug licking its lips"
                            />
                            <StaticImage
                                placeholder="none"
                                className="about-featured-image"
                                src="../images/dr-stein.jpeg"
                                alt="pug licking its lips"
                            />
                        </div>
                        <h3>
                            Dr. Stein
                            <br />
                            <span>DVM</span>
                        </h3>
                    </div>
                    <div className="body">
                        <div
                            style={{
                                padding: '2em 0em 0em',
                                borderTop: `1px solid ${colors.darkGreen}`,
                            }}
                            className="nip"
                        >
                            <h3 style={{}}>
                                Dr. Stein
                                <br />
                                <span style={{ color: `${colors.mediumGreen}` }}>DVM</span>
                            </h3>
                        </div>
                        <p>
                            Dr. Austin Stein is originally from Syosset New York on Long Island,
                            where he grew up with two younger brothers and a family dog. He earned
                            his Bachelor of Science degree from Pennsylvania State University and
                            his Doctorate of Veterinary Medicine from Western University of Health
                            Sciences in Los Angeles California. Following veterinary school, Dr.
                            Stein completed an internship in small animal medicine and surgery at
                            Veterinary Emergency and Referral Group in Brooklyn.
                        </p>
                        <p>
                            He thinks that the most important thing is the human animal bond and his
                            role in maintaining it for his owners and patients. He currently resides
                            in Gowanus, Brooklyn with his wife Brittany and labradoodle Duke. In his
                            spare time he enjoys watching movies, reading, playing basketball and
                            going on adventures with his friends and family.
                        </p>
                    </div>
                    <div className="doctor-pics-container">
                        <div className="doctor-pics">
                            <StaticImage
                                quality={100}
                                placeholder="none"
                                className="doctor-pic underlayer"
                                src="../images/dr-stein-2.jpeg"
                                alt="Dr. Stein with a black dog"
                            />

                            <StaticImage
                                placeholder="none"
                                quality={100}
                                className="doctor-pic"
                                src="../images/dr-stein-2.jpeg"
                                alt="Dr. Stein frontal"
                            />
                        </div>

                        <div className="doctor-pics second">
                            <StaticImage
                                quality={100}
                                placeholder="none"
                                className="doctor-pic underlayer"
                                src="../images/dr-stein-3.jpeg"
                                alt="Dr. Stein with a black dog"
                            />
                            <StaticImage
                                placeholder="none"
                                quality={100}
                                className="doctor-pic"
                                src="../images/dr-stein-3.jpeg"
                                alt="Dr. Stein frontal"
                            />
                        </div>
                    </div>
                    <div style={{ margin: '4em 0em' }}>
                        <div className="second-doctor-container">
                            <div>
                                <div className="second-image-container">
                                    <StaticImage
                                        placeholder="none"
                                        className="about-bottom-layer"
                                        src="../images/second-doctor.jpg"
                                        alt="blurry pug licking its lips"
                                    />
                                    <StaticImage
                                        placeholder="none"
                                        className="about-featured-image"
                                        src="../images/second-doctor.jpg"
                                        alt="pug licking its lips"
                                    />
                                </div>
                                <div className="second-title">
                                    <h3 style={{}}>
                                        Emily Sanmartin
                                        <br />
                                        <span>Head Technician </span>
                                    </h3>
                                </div>
                            </div>
                            <div className="paragraph">
                                <div
                                    style={{
                                        padding: '2em 0em 0em',
                                        borderTop: `1px solid ${colors.darkGreen}`,
                                    }}
                                    className="nip"
                                >
                                    <h3 style={{}}>
                                        Emily Sanmartin
                                        <br />
                                        <span style={{ color: `${colors.mediumGreen}` }}>
                                            Head Technician{' '}
                                        </span>
                                    </h3>
                                </div>
                                <p>
                                    I grew up in Ecuador surrounded by animals and always knew I
                                    wanted to do more. Living in New York and being able to help
                                    animals and be their voice has always been a dream. I’m
                                    currently enrolled in school to become a Licensed Veterinary
                                    Technician and live in Brooklyn, NY with two tuxedo cats with
                                    the ultimate goal of having a huge house with lots of other
                                    animals.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: '4em 0em' }}>
                        <div className="second-doctor-container">
                            <div>
                                <div className="second-image-container">
                                    <StaticImage
                                        placeholder="none"
                                        className="about-bottom-layer"
                                        src="../images/sofia.jpg"
                                        alt="blurry pug licking its lips"
                                    />
                                    <StaticImage
                                        placeholder="none"
                                        className="about-featured-image"
                                        src="../images/sofia.jpg"
                                        alt="pug licking its lips"
                                    />
                                </div>
                                <div className="second-title">
                                    <h3 style={{}}>
                                        Sofia
                                        <br />
                                        <span> Client Service Representative </span>
                                    </h3>
                                </div>
                            </div>
                            <div className="paragraph">
                                <div
                                    style={{
                                        padding: '2em 0em 0em',
                                        borderTop: `1px solid ${colors.darkGreen}`,
                                    }}
                                    className="nip"
                                >
                                    <h3 style={{}}>
                                        Sofia
                                        <br />
                                        <span style={{ color: `${colors.mediumGreen}` }}>
                                            Client Service Representative{' '}
                                        </span>
                                    </h3>
                                </div>
                                <p>
                                    Starting at a young age animals were always my first love. I
                                    grew up in Brooklyn but originally from Guatemala. Currently I
                                    have five rescue cats at home. What I love about working with
                                    animals is getting to know each animal individually, learning
                                    about their story and helping them in any way I can
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{ margin: '4em 0em' }}>
                        <div className="second-doctor-container">
                            <div>
                                <div className="second-image-container">
                                    <StaticImage
                                        placeholder="none"
                                        className="about-bottom-layer"
                                        src="../images/third-doctor.jpg"
                                        alt="blurry pug licking its lips"
                                    />
                                    <StaticImage
                                        placeholder="none"
                                        className="about-featured-image"
                                        src="../images/third-doctor.jpg"
                                        alt="pug licking its lips"
                                    />
                                </div>
                                <div className="second-title">
                                    <h3 style={{}}>
                                        Vanessa
                                        <br />
                                        <span>Client Service Representative</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="paragraph">
                                <div
                                    style={{
                                        padding: '2em 0em 0em',
                                        borderTop: `1px solid ${colors.darkGreen}`,
                                    }}
                                    className="a nip"
                                >
                                    <h3 style={{}}>
                                        Vanessa
                                        <br />
                                        <span style={{ color: `${colors.mediumGreen}` }}>
                                            Client Service Representative
                                        </span>
                                    </h3>
                                </div>
                                <p>
                                    Hi everyone! I was born and raised in Milwaukee, Wisconsin and
                                    moved to Brooklyn almost two years ago. I have loved animals
                                    since I was a young kid and after I left college in Chicago, I
                                    naturally fell into a role working with them. Living in Brooklyn
                                    has been great because I love to try new restaurants and see all
                                    of the history in the city. I currently share my home with one
                                    very handsome cat named Lionel
                                </p>
                            </div>
                        </div>
                    </div> */}
                </StyledAgainTemplate>
            </DefaultLayout>
        </>
    );
};

export default AboutUs;
