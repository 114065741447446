/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { colors, device } from '../GlobalStyles';
import styled from '@emotion/styled';
import { Button } from '../StyledComponents';
import logo from '../../../images/icons/new-greenpoint-logo.svg';

const StyledButton = styled(Button)`
    padding: 0.5em 4em;
`;

interface Props {
    active?: boolean;
}

const NavButton = styled(Button)<Props>`
    background: ${colors.navy};
    display: flex;
    width: 60px;
    opacity: ${({ active }) => (active ? '0' : '1')};

    .service {
        div {
            background: red;
        }
    }
    span {
        display: none;
    }
    img {
        width: 30px;
    }
    @media ${device.tablet} {
        width: 180px;
        align-items: center;

        span {
            display: block;
            margin-left: -0.75em;
        }
    }
    @media ${device.laptop} {
        position: relative;
        left: 18em;
        background: ${colors.mediumGreen};
        span {
            display: block;
            margin-left: 0.25em;
        }
    }
`;
const StyledNav = styled.nav<StyledNavProps>`
    position: absolute;
    overflow-x: hidden;
    z-index: 4;
    padding: 2em 1em;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: flex-start;
    justify-content: space-between;

    img {
        width: 98.61px;
        margin-right: 4em;
    }
    /* overflow: hidden; */

    .mobile-panel {
        border-radius: 0px 0 0 40px;
        width: 62.5vw;
        right: 0em;
        top: 0em;

        transform: ${({ active }) => (active ? 'translateX(0%)' : 'translateX(100%)')};
        background: white;
        position: absolute;
        height: 75vh;
        .nav-panel-list {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-weight: 600;
            list-style-type: none;
            margin-top: 16vh;
            li {
                z-index: 800;
                font-style: normal;
                font-weight: bold;
                margin: 0.5em 0em;
                font-size: 32px;
                line-height: 38px;
                letter-spacing: 1px;
            }
            button {
                margin-top: 1em;

                bottom: 2em;
            }
        }
    }
    ul {
        display: none;
    }
    .burger {
        z-index: 300;
        div {
            height: 5px;
            margin: 0.7em 0em;
            width: 42px;
            background: ${colors.navy};
        }
    }
    @media ${device.tablet} {
        img {
            width: 142px;
        }
    }
    @media ${device.laptop} {
        align-items: center;
        padding: 0em 4em;
        height: 15vh;
        .mobile-panel {
            display: none;
        }
        img {
            width: 142px;
            margin-right: 0em;
        }

        ul {
            position: absolute;
            left: 13em;
            display: flex;
            .arrow {
                background: red;
                position: relative;
                left: 0.2em;
            }
            li {
                display: flex;
                align-items: CENTER;
                cursor: pointer;

                font-weight: 600;
                font-size: 18px;
                list-style-type: none;
                margin: 0em 2em;
            }
        }
        .burger {
            display: none;
        }
    }
    @media ${device.laptopL} {
        ul {
            display: flex;
        }
    }
    @media ${device.desktop} {
    }
`;

interface StyledNavProps {
    active: boolean;
}

export const Nav: React.FC = () => {
    const [active, setActive] = useState(false);

    const panel = () => {
        setActive(active => !active);
    };
    return (
        <StyledNav active={active}>
            <Link to="/">
                <img src={logo} alt="greenpoint vet logo" />
            </Link>
            <div></div>
            <ul>
                {/*<Link to="/about-us">
                    <li>About</li>
                </Link>
                */}
                <Link to="/services/">
                    <li className="service">Services </li>
                </Link>
                <Link to="/contact/">
                    <li>Contact</li>
                </Link>
                <Link to="/about-us/">
                    <li>About</li>
                </Link>
                <Link to="#wellness">
                    <li>Wellness Plan</li>
                </Link>
                <Link to="/grieving-a-pet/">
                    <li>Grieving a Pet</li>
                </Link>
            </ul>
            <a rel="noreferrer" href="tel:9179700950">
                <NavButton active={active} style={{ boxShadow: 'none' }}>
                    {' '}
                    <StaticImage
                        className="white-mobile"
                        placeholder="none"
                        src="../../../images/icons/white-mobile.svg"
                        alt="white mobile icon"
                    />
                    <span> Call Us Now!</span>
                </NavButton>
            </a>
            <div onClick={() => panel()} className="burger">
                <div className="line-one"></div>
                <div className="line-two"></div>
                <div className="line-three"></div>
            </div>
            <div className="panel-container">
                <div className="mobile-panel">
                    <ul className="nav-panel-list">
                        <Link to="/services/">
                            <li>Services</li>
                        </Link>
                        <Link to="/contact/">
                            <li>Contact</li>
                        </Link>
                        <Link to="/about-us/">
                            <li>About</li>
                        </Link>
                        <Link to="#wellness">
                            <li>Wellness</li>
                        </Link>
                        <Link to="/grieving-a-pet/">
                            <li>Grieving a Pet</li>
                        </Link>
                        <a
                            target="_blank"
                            href="mailto:Info@greenpointvetcare.com"
                            rel="noreferrer"
                        >
                            <StyledButton style={{ position: 'absolute' }}>Email</StyledButton>
                        </a>
                    </ul>
                </div>
            </div>
        </StyledNav>
    );
};
