import * as React from 'react';
import { ContactForm } from '../components/ContactForm';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Hero } from '../components/Hero';
import { Seo } from '../components/Seo';

const Contact: Page = () => {
    return (
        <>
            <Seo
                title="Contact Us - Greenpoint BK, Vet Care"
                description="Contact veterinary local staff in Greenpoint, Brooklyn"
            />
            <DefaultLayout>
                <Hero white title="Contact Us" />
                <ContactForm />
            </DefaultLayout>
        </>
    );
};

export default Contact;
