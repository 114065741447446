import * as React from 'react';
import { graphql } from 'gatsby';
//components
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { colors, device } from '../components/layout/GlobalStyles';
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Template } from '../components/layout/StyledComponents';
import { Hero } from '../components/Hero';
import { HomeServices } from '../components/HomeServices';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '../components/Seo';

const StyledTemplate = styled(Template)`
    .video-container {
        display: flex;
        justify-content: center;
    }
    iframe {
        max-height: 300px;
        margin: 0 auto;
        border-radius: 50px;
    }
    @media ${device.tablet} {
        .video-container {
            margin: 2em 0em;
        }
        iframe {
            max-height: 550px;
        }
    }
`;

interface Props {
    data: {
        mdx: {
            frontmatter: {
                title: string;
                heroTitle: string;
                seoTitle: string;
                seoDescription: string;
                altTag: string;
                image: IGatsbyImageData;
            };
            body: string;
        };
    };
}

const Services: React.FC<Props> = ({ data }) => {
    const image = getImage(data.mdx.frontmatter.image);
    return (
        <>
            <DefaultLayout>
                <Seo
                    title={data.mdx.frontmatter.seoTitle}
                    description={data.mdx.frontmatter.seoDescription}
                />
                <Hero title={data.mdx.frontmatter.title} />
                <StyledTemplate>
                    <div className="image-container">
                        {image && (
                            <>
                                <GatsbyImage
                                    imgStyle={{ borderRadius: '33px' }}
                                    className="bottom-layer"
                                    image={image}
                                    alt={`backdrop ${data.mdx.frontmatter.altTag}`}
                                />
                                <GatsbyImage
                                    imgStyle={{ borderRadius: '33px' }}
                                    className="featured-image"
                                    image={image}
                                    alt={data.mdx.frontmatter.altTag}
                                />
                            </>
                        )}
                    </div>
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    <div className="second-image-container">
                        <div className="pic-text">
                            <h4 style={{ marginBottom: '1em' }}>
                                Don’t see what you need? Chances are we do it.
                            </h4>
                            <span>Call us! 917-970-0950</span>
                        </div>
                        <StaticImage
                            placeholder="none"
                            imgStyle={{ borderRadius: '33px' }}
                            className="second-bottom-layer"
                            src="../images/cat-finger.jpeg"
                            alt="cat reaching to human"
                        />
                        <StaticImage
                            placeholder="none"
                            imgStyle={{ borderRadius: '33px' }}
                            className="second-featured-image"
                            src="../images/cat-finger.jpeg"
                            alt="cat reaching to human"
                        />
                    </div>
                </StyledTemplate>

                <HomeServices />
            </DefaultLayout>
        </>
    );
};

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                listTitle
                seoTitle
                seoDescription
                slug
                altTag
                image {
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                }
            }
            body
            id
        }
    }
`;

export default Services;
