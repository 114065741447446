import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from './StyledComponents';
import { colors, device } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { ThankYou } from '../ThankYou';

const StyledAppointment = styled.div`
    padding: 2em 1em;
    z-index: 0;
    .pictures {
        display: none;
    }
    .date {
    }
    h2 {
        padding: 0em 0em 2em;
        font-size: 36px;
        line-height: 48px;
    }
    .navy-button {
        margin-bottom: 4em;
        margin-right: 6em;
        font-weight: 600;
        background: #fafafa;
        border: 1px solid ${colors.navy};
        border-radius: 8px;
        padding: 1em;
    }
    .thanks-container {
        display: flex;
        height: 100vh;
        align-items: CENTER;
    }
    textarea {
        border-radius: 8px;
        margin: 0em 0em 3em;
        border: none;
        background: white;
        padding: 1em;
        width: 300px;
        height: 100px;
    }
    form {
        z-index: 400;
        .input {
            position: relative;
            display: flex;
            margin: 2.5em 0em;
            z-index: 3000;
            align-items: center;
            .spec {
                left: 0.5em;
                width: auto;
                min-height: 20px;
                max-height: 30px;
            }
            img,
            input {
                position: absolute;
            }
            input {
                text-indent: 50px;
                width: 230px;
                border: none;
                padding: 1.2em 0.75em;
                border-radius: 8px;
            }
        }
    }

    @media ${device.laptop} {
        position: relative;

        padding: 0em 0em;
        display: flex;

        .left-column {
            z-index: 0;
            width: 50vw;
            display: flex;
            flex-direction: column;
            padding-left: 4em;
            align-items: flex-start;
        }
        .pictures {
            bottom: 12em;
            margin-bottom: -12em;
            width: 50vw;
            position: relative;
            img {
                z-index: -100;
            }
            display: block;
        }
    }
    @media ${device.laptopL} {
        h2 {
            font-size: 52px;
            margin-bottom: -1em;
        }
        textarea {
            width: 500px;
        }

        .flex-inputs {
            width: 300px;
            justify-content: space-between;
            display: flex;
        }
        .pictures {
            bottom: 20em;
            margin-bottom: -20em;
        }
        h2 {
            font-size: 48px;
        }
        .left-column {
            padding-left: 6em;
        }
    }
    @media ${device.desktop} {
        form {
            max-width: 380px;
        }
        .pictures {
            bottom: 25em;
            margin-bottom: -18em;
        }
        h2 {
            font-size: 52px;
            margin-bottom: -1em;
        }
        .left-column {
            height: 300px;
            padding-left: 8em;
        }
    }
    @media ${device.desktopL} {
        padding-left: 3em;
        .pictures {
            bottom: 30em;
            margin-bottom: -27em;
        }
    }
`;

const StyledButton = styled(Button)`
    background: ${colors.darkGreen};
    margin-bottom: 2em;
`;

export const Appointment: React.FC = () => {
    const [submitted, setSubmitted] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitted(true);

        const data = Object.fromEntries(new FormData(e.target));
        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        e.target.reset();
    };
    return (
        <>
            <StyledAppointment>
                <div className="left-column">
                    {!submitted ? (
                        <>
                            <h2>Book an Appointment</h2>
                            {/*
                            <div className="buttons">
                                <button className="navy-button">new</button>
                                <button className="navy-button">returning</button>
                            </div>
                            */}
                            <form onSubmit={onSubmit}>
                                <div className="flex-inputs">
                                    <div>
                                        <div className="input">
                                            <input name="petName" placeholder="Pets' Name" />
                                            <StaticImage
                                                placeholder="none"
                                                className="spec"
                                                src="../../images/icons/dog.svg"
                                                alt="a little dog"
                                            />
                                        </div>
                                        <div className="input">
                                            <input name="humanName" placeholder="Human's Name" />
                                            <StaticImage
                                                placeholder="none"
                                                className="spec"
                                                src="../../images/icons/human.svg"
                                                alt="avatar person"
                                            />
                                        </div>
                                        <div className="input">
                                            <input type="tel" name="phone" placeholder="Phone" />
                                            <StaticImage
                                                placeholder="none"
                                                className="spec"
                                                src="../../images/icons/phone.svg"
                                                alt="a mobile phone"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="input">
                                            <input required name="petType" placeholder="Pet Type" />
                                            <StaticImage
                                                placeholder="none"
                                                className="spec"
                                                style={{ fill: 'red' }}
                                                src="../../images/icons/pet-type.svg"
                                                alt="little animal head"
                                            />
                                        </div>
                                        <div className="input">
                                            <input name="email" required placeholder="Email" />
                                            <StaticImage
                                                placeholder="none"
                                                className="spec"
                                                style={{ fill: 'black' }}
                                                src="../../images/icons/email-form.svg"
                                                alt="email envelope"
                                            />
                                        </div>
                                        <div className="input">
                                            <input
                                                style={{
                                                    textIndent: '23px',
                                                    color: 'grey',
                                                    background: `white`,
                                                    padding: '1em',
                                                }}
                                                required
                                                type="date"
                                                name="Date"
                                                placeholder="Select Date"
                                            />
                                            <StaticImage
                                                placeholder="none"
                                                className="spec"
                                                imgStyle={{
                                                    fill: 'black',
                                                    zIndex: '-1',
                                                    display: 'hidden',
                                                }}
                                                src="../../images/icons/email-form.svg"
                                                alt="email envelope"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <textarea
                                        name="comments"
                                        placeholder="Add any comments for our
                        staff or doctors"
                                    />
                                </div>

                                <StyledButton wide>Schedule</StyledButton>
                            </form>
                        </>
                    ) : (
                        <div className="thanks-container">
                            <ThankYou />
                        </div>
                    )}
                </div>
                <div className="pictures">
                    <StaticImage
                        placeholder="none"
                        className="dog"
                        src="../../images/dog.jpeg"
                        alt="a pug dog"
                    />
                </div>
            </StyledAppointment>
        </>
    );
};
