import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import waves from '../images/wellness-waves.png';

const StyledWellness = styled.div`
    background: #eff9f0;
    padding: 72px 0 0;
    .wellness-logo {
        display: none;
    }
    display: flex;
    h3 {
        line-height: 38px;
        padding-bottom: 0.5em;
        text-align: center;
    }
    flex-direction: column;
    align-items: center;
    .box {
        li {
            margin: 0.5em 0em;
        }
        margin: 1em;
        padding: 2em;
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        background: #eff9f0;
        border: 2px solid #ffffff;
        box-shadow: -50px -2000px 100px #ffffff, 30px 30px 80px rgba(55, 170, 73, 0.2),
            inset 10px 10px 20px rgba(255, 255, 255, 0.5);
        border-radius: 50px;
        h3 {
            font-size: 32px;
        }
        p {
            margin: 1em 0em;
            font-weight: 800;
            z-index: 200;
        }
        ul {
            padding-left: 1.75em;
            font-weight: 500;
        }
    }
    @media ${device.laptop} {
        padding: 127px 0 0;
        .box {
            h3 {
                line-height: 38px;
                padding-bottom: 0.2em;
                text-align: center;
            }
        }
    }
`;

export const LateFee: React.FC = () => {
    return (
        <>
            <StyledWellness>
                <div className="box">
                    <h3>Late Fee / Cancellation Policy</h3>
                    <h4>Same Day cancellation or no show</h4>
                    <h4 style={{ fontSize: '24px', color: `${colors.mediumGreen}` }}>
                        three strike rule
                    </h4>

                    <ul>
                        <li>
                            First Strike:{' '}
                            <span style={{ color: `${colors.aqua}` }}>No Penalty</span>
                        </li>
                        <li>
                            Second Strike:{' '}
                            <span style={{ color: `${colors.aqua}` }}>
                                We will require a credit card to be on file
                            </span>
                        </li>
                        <li>
                            Third Strike:{' '}
                            <span style={{ color: `${colors.aqua}` }}>
                                We will charge exam fee ($95)
                            </span>
                        </li>
                    </ul>
                </div>
            </StyledWellness>
        </>
    );
};
