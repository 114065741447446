import { Nav } from '../components/layout/nav/Nav';
import { Footer } from '../components/layout/footer/Footer';
import { Map } from '../components/layout/Map';
import { Appointment } from '../components/layout/Appointment';
import { Faq } from '../components/layout/Faq';
import { Reviews } from '../components/layout/Reviews';
import { Insurance } from '../components/layout/Insurance';
import { ThemeProvider } from '@emotion/react';
import { GlobalStyles, colors } from '../components/layout/GlobalStyles';
import { Wellness } from '../components/Wellness';
import { LateFee } from '../components/Late-Fee';

interface Props {
    faq?: boolean;
}

export const DefaultLayout: React.FC<Props> = ({ children, faq }) => {
    return (
        <div id="layout">
            <ThemeProvider theme={colors}>
                <GlobalStyles />
                <Nav />
                {children}
                <Map />
                <Appointment />
                <LateFee />
                <Wellness />

                <Insurance />
                {false && faq && <Faq />}
                {false && faq && <Reviews />}
                <Footer />
            </ThemeProvider>
        </div>
    );
};
