import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';

interface Props {
    wide?: boolean;
}

export const Button = styled.button<Props>`
    color: ${colors.white};
    border: none;
    background: ${colors.mediumGreen};
    font-weight: 600;
    padding: ${({ wide }) => (wide ? '.75em 8.5em' : '.75em 1em')};
    border-radius: 40px;
    font-size: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    line-height: 28px;
    &:hover {
        box-shadow: rgba(50, 50, 93, 0.2) 0px 20px 20px -12px,
            rgba(0, 0, 0, 0.2) 0px 18px 26px -18px;
    }
`;

export const Template = styled.section`
    padding: 1em;
    .second-image-container {
        display: flex;
        justify-content: center;
    }
    .image-container,
    .second-image-container {
        .pic-text {
            h4 {
                font-size: 22px;
                color: ${colors.mediumGreen};
            }
            span {
                text-align: center;
                font-weight: 600;
            }
            z-index: 4;
            position: absolute;
        }

        position: relative;
        margin: 1em 0em;
        margin-bottom: 2em;
        .second-bottom-layer {
            top: 6em;
            border-radius: 33px;
            left: 1em;
            filter: blur(60px);
            position: absolute;
            height: 90%;
        }

        .bottom-layer,
        .about-bottom-layer {
            top: 3em;
            border-radius: 33px;
            opacity: 0.9;
            left: 1em;
            filter: blur(35px);
            position: absolute;
            height: 90%;
        }
        .featured-image,
        .second-featured-image {
            border-radius: 33px;
        }
    }
    .about-featured-image,
    .about-bottom-layer {
        border-radius: 32px;
        height: 390px;
        margin-bottom: 2em;
    }
    .featured-image {
        max-height: 480px;
    }
    .second-featured-image {
        max-height: 420px;
    }
    a {
        text-decoration: underline;
        color: ${colors.mediumGreen};
    }
    h2 {
        margin: 1em 0em 0.5em 0em;
        color: ${colors.navy};
        font-size: 36px;
        line-height: 48px;
        letter-spacing: 1px;
    }
    ul {
        color: ${colors.navy};
        li {
            font-weight: 500;
        }
    }
    p {
        margin: 1em 0em;
        text-indent: 15px;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.75px;
        color: ${colors.navy};
    }
    h1 {
        color: ${colors.mediumGreen};
    }
    .second-featured-image,
    .second-bottom-layer {
        display: none;
    }
    @media ${device.tablet} {
        .about-featured-image,
        .about-bottom-layer {
            border-radius: 300px;
            height: 390px;
        }
        .about-featured-image {
            max-width: 400px;
        }
        .about-bottom-layer {
            max-width: 300px;
        }
        padding: 0em 4em;
        h3 {
            font-size: 32px;
            margin: 3.5em 0em 1em;
            text-decoration: underline;
            color: ${colors.navy};
        }
        h4 {
            color: ${colors.navy};
            font-size: 22px;
        }
    }
    @media ${device.laptopL} {
        .second-image-container {
            padding-top: 2em;
            .pic-text {
                left: 10em;
                top: 30%;
                h4 {
                    margin-bottom: 1em;
                    width: 50%;
                    font-size: 48px;
                    line-height: 66px;
                }
            }
        }
        .second-featured-image,
        .second-bottom-layer {
            display: block;
        }
        max-width: 1280px;
        margin: 0 auto;

        .featured-image {
            max-height: 480px;
        }
        .second-featured-image {
            max-height: 420px;
        }
        .second-featured-image,
        .second-bottom-layer {
            max-width: 1180px;
            margin: 0 auto;
        }
    }
    @media ${device.desktop} {
        margin-top: -3em;
        max-width: 1480px;
        .second-image-container {
            padding-top: 2em;
            .pic-text {
                left: 10em;
                top: 30%;
                h4 {
                    margin-bottom: 1em;
                    width: 50%;
                }
                margin: 0 auto;
                .featured-image,
                .second-featured-image {
                    max-height: 540px;
                }
                .second-featured-image,
                .second-bottom-layer {
                    max-width: 1320px;
                    margin: 0 auto;
                }
                h2 {
                    font-size: 42px;
                }
                p {
                    margin: 1em 0em;
                    line-height: 32px;
                    font-size: 21px;
                }
            }
        }
    }
`;
