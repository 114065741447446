import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';

const StyledThankYou = styled.div`
    margin: 0 auto;
    .thanks {
        background: #57cc98;
        border-radius: 64px;
        padding: 8em 2em;
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: CENTER;

        p {
            color: white;
            margin: 0.5em 0em;
        }
        p:first-of-type {
            color: black;
            font-weight: 600;
            font-size: 50px;
        }
    }
`;

export const ThankYou: React.FC = () => {
    return (
        <>
            <StyledThankYou>
                <div className="thanks-container">
                    <div className="thanks">
                        <StaticImage
                            placeholder="none"
                            src="../images/icons/checkmark.svg"
                            alt="check mark icon"
                        />
                        <p>thank you!</p>
                        <p>Someone will be right with you to assist shortly.</p>
                    </div>
                </div>
            </StyledThankYou>
        </>
    );
};
