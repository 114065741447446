/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledFAQ = styled.div`
    padding: 1em;
    h2 {
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: 1px;
        color: ${colors.textGreen};
    }
    .image {
        border: none;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 1em;
        top: 1.5em;
    }
    .item {
        position: relative;
        background: rgba(250, 250, 250, 0.8);
        box-shadow: -8px -8px 50px #ffffff, 8px 8px 32px rgba(0, 0, 0, 0.2);
        border-radius: 32px;
        margin: 1em 0.2em;
        padding: 1em;
    }
    p {
        margin-top: 1em;
        line-height: 26px;
        font-size: 16px;
        font-weight: 400;
        display: block;
    }
    .hidden {
        display: none;
    }

    span {
        font-weight: 600;
    }
    @media ${device.tablet} {
        .image {
            top: 1.5em;
        }
        .item {
            padding: 1.5em;
            cursor: pointer;
        }
        h2 {
            margin-bottom: 2em;
        }
        padding: 4em 0em;
        margin: 0em 4em;
        max-width: 780px;
    }
    @media ${device.laptopL} {
        .image {
            top: 1.8em;
            right: 1.5em;
        }
        .item {
            padding: 2em 1.5em;
        }
        h2 {
            font-size: 59px;
            line-height: 66px;
            margin-bottom: 1em;
        }
        padding: 5em 0em;
        margin: 0em 4em;
        max-width: 1060px;
        .item:hover {
            padding: 2em 2em;
        }
    }
    @media ${device.desktop} {
        max-width: 1360px;
        padding-left: 0em;
    }
`;

export const Faq: React.FC = () => {
    const [index, setIndex] = useState(null);
    const [opened, setOpened] = useState(false);

    const close = () => {
        setIndex(null);
        setOpened(true);
    };

    const questions = [
        {
            question: 'What counts as a dental emergency?',
            answer: 'Broken teeth, extreme pain, and displaced teeth can be considered dental emergencies. This is especially  applicable if you got the problem from accidents.',
        },
        {
            question: 'How do I schedule an emergency dentist visit?',
            answer: 'Broken teeth, extreme pain, and displaced teeth can be considered dental emergencies. This is especially  applicable if you got the problem from accidents.',
        },
        {
            question: 'How do I schedule an emergency dentist visit?',
            answer: 'Broken teeth, extreme pain, and displaced teeth can be considered dental emergencies. This is especially  applicable if you got the problem from accidents.',
        },
        {
            question: 'How do I schedule an emergency dentist visit?',
            answer: 'Broken teeth, extreme pain, and displaced teeth can be considered dental emergencies. This is especially  applicable if you got the problem from accidents.',
        },
    ];
    return (
        <StyledFAQ>
            <h2>Frequently Asked Questions</h2>
            {questions.map((question, i) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                    key={question.question}
                    className="item"
                    onClick={() => {
                        setIndex(i);
                        if (index === i) {
                            close();
                        }
                    }}
                >
                    {index === i ? (
                        <StaticImage
                            placeholder="none"
                            className="image"
                            src="../../images/icons/minus-sign.svg"
                            alt="minus sign"
                        />
                    ) : (
                        <StaticImage
                            placeholder="none"
                            className="image"
                            placeholder="none"
                            src="../../images/icons/plus-sign.svg"
                            alt="plus sign"
                        />
                    )}
                    <h3>Q: {question.question}</h3>
                    <p className={`${index === i ? '' : 'hidden'}`}>
                        <span>A:</span> {question.answer}
                    </p>
                </div>
            ))}
        </StyledFAQ>
    );
};
