import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyleInsurance = styled.section`
    overflow-x: scroll;
    position: relative;
    z-index: 200;

    span {
        bottom: 2em;
        font-weight: 600;
    }
    background: ${colors.lightGreen};

    width: 100vw;
    padding: 0em 1em;
    h2 {
        margin: 1em 0em 0.5em;
        font-size: 48px;
        line-height: 60px;
    }
    .scroll {
        overflow-y: hidden;
        margin-top: 2em;
        margin-bottom: 3em;
        width: 300vw;
        height: 100px;
        justify-content: space-between;
        align-items: center;
        img {
            width: 200px;
            height: auto;
        }
        display: flex;
    }
    @media ${device.tablet} {
        h2 {
            padding-left: 0.8em;
        }
        .scroll {
            margin-top: 2em;
            margin-bottom: 3em;
            width: 150vw;
        }
    }
    @media ${device.laptop} {
        span {
            display: none;
        }
        padding: 3em 2em;
        overflow-x: hidden;
        h2 {
            margin: 1em 0em;

            font-size: 48px;
        }
        .scroll {
            width: 95vw;
            padding: 0em 3em;
            justify-content: space-between;
        }
    }
    @media ${device.desktop} {
        h2 {
            padding-left: 1em;
        }
        span {
            display: none;
            padding-left: 3em;
        }
        overflow-x: hidden;
    }
`;

export const Insurance: React.FC = () => {
    return (
        <StyleInsurance>
            <h2>Trusted Brands</h2>
            <span>Scroll below:</span>
            <div className="scroll">
                <StaticImage
                    placeholder="none"
                    src="../../images/brands/logo-vetsource.png"
                    alt="vetsource brand logo"
                />
                <StaticImage
                    placeholder="none"
                    src="../../images/brands/logo-wedgewood.png"
                    alt="wedgewood brand logo"
                />
                <StaticImage
                    placeholder="none"
                    src="../../images/brands/logo-loyal.svg"
                    alt="royal canin logo"
                />
                <StaticImage
                    placeholder="none"
                    src="../../images/brands/logo-antech.svg"
                    alt="antech brand logo"
                />
                <StaticImage
                    placeholder="none"
                    src="../../images/brands/logo-merk.svg"
                    alt="merk brand logo"
                />
            </div>
        </StyleInsurance>
    );
};
