import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from '../GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledFooter = styled.footer`
    background: ${colors.navy};
    color: white;
    padding: 1em;
    ul {
        padding-left: 0em;
    }
    .left-column {
        img {
            width: auto;
            height: 25px;
            margin-right: 2em;
        }
        .section {
            padding: 1em 0em;
        }
    }
    .social {
        display: flex;
        margin-top: 1em;
        justify-content: space-around;
        img {
            width: auto;
            height: 25px;
        }
    }
    h3 {
        font-size: 36px;
        margin: 0.75em 0em;
        text-align: center;
    }
    ul {
        margin-left: 2em;
        list-style: none;
        li {
            margin: 1em 0em;
        }
    }
    @media ${device.laptop} {
        padding: 35px 0px 45px;
        ul {
            padding-left: 2.2em;
        }
        display: flex;
        justify-content: space-around;
        .social {
            justify-content: center;
            margin-left: -18em;
            div {
                margin-right: 24px;
            }
        }
        ul {
            margin-left: -2em;
        }
        h3 {
            text-align: left;
        }
    }
`;

export const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <div className="left-column">
                <h3>Find Us</h3>
                <a
                    style={{ fontWeight: '500', color: `${colors.white}` }}
                    rel="noreferrer"
                    target="__blank"
                    href="https://goo.gl/maps/xiDE8LNB2HtJygKu9"
                >
                    <p
                        style={{
                            fontWeight: 'bold',
                            fontSize: '1.3em',
                            marginBottom: '1em',
                        }}
                    >
                        Greenpoint Veterinary Care
                    </p>
                    <div className="section">
                        {' '}
                        <StaticImage
                            placeholder="none"
                            src="../../../images/icons/footer-map.svg"
                            alt="map marker"
                        />
                        211 McGuinness Blvd, Suite 6, Brooklyn, NY 11222
                    </div>
                </a>
                <a
                    style={{ fontWeight: '500', color: `${colors.white}` }}
                    href="mailto:info@greenpointvetcare.com"
                >
                    <div className="section">
                        {' '}
                        <StaticImage
                            placeholder="none"
                            src="../../../images/icons/footer-email.svg"
                            alt="map marker"
                        />
                        Info@greenpointvetcare.com
                    </div>
                </a>
                <a style={{ fontWeight: '500', color: `${colors.white}` }} href="tel:9179700950">
                    <div className="section">
                        {' '}
                        <StaticImage
                            placeholder="none"
                            src="../../../images/icons/white-mobile.svg"
                            alt="map marker"
                        />
                        <span style={{ position: 'relative', right: '1.2em' }}>917-970-0950</span>
                    </div>
                </a>
                {/*
                <div>
                    <StaticImage src="../../../images/icons/footer-email.svg" alt="map marker" />
                    drstein@greenpointvetcare.com
                </div>
                <div>
                    {' '}
                    <StaticImage src="../../../images/icons/mobile-footer.svg" alt="map marker" />
                    +1 (212) 000 0000
                </div>
                */}
                <div className="social">
                    {/* <StaticImage
                        placeholder="none"
                        src="../../../images/icons/facebook.svg"
                        alt="facebook logo"
                    /> */}
                    <a
                        href="https://www.instagram.com/p/CfG_NJCO5Dg/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <StaticImage
                            src="../../../images/icons/instagram-logo.svg"
                            alt="instagram logo"
                            placeholder="none"
                        />
                    </a>
                    <a
                        href="https://www.google.com/maps/place/Greenpoint+Veterinary+Care/@40.7296014,-73.9535709,17z/data=!4m7!3m6!1s0x89c25943adfcac41:0xd98626d50d991bde!8m2!3d40.7295974!4d-73.9513822!9m1!1b1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <StaticImage
                            placeholder="none"
                            src="../../../images/icons/white-google-logo.svg"
                            alt="google logo"
                        />
                    </a>
                </div>
            </div>
            <div className="right-column">
                <h3>Navigation</h3>
                <ul>
                    {/*<li>About Us</li>*/}
                    <Link to="/services/">
                        <li style={{ fontWeight: '400', color: 'white' }}>Services</li>
                    </Link>
                    <Link to="/about-us/">
                        <li style={{ fontWeight: '400', color: 'white' }}>About Us</li>
                    </Link>
                    <Link to="/grieving-a-pet/">
                        <li style={{ fontWeight: '400', color: 'white' }}>Grieving a Pet</li>
                    </Link>
                </ul>
            </div>
        </StyledFooter>
    );
};
