import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import waves from '../images/wellness-waves.png';

const StyledWellness = styled.div`
    background: #eff9f0;
    .wellness-logo {
        display: none;
    }
    position: relative;
    height: 240vh;
    padding: 2em 0em;
    z-index: -2;
    .first-box {
        min-height: 740px;
    }
    .box-container {
        z-index: 1;
        position: relative;
        bottom: 48em;
        display: flex;
        flex-direction: column;
        align-items: center;
        .box {
            li {
                margin: 0.5em 0em;
            }
            margin: 1em;
            padding: 2em;
            width: 342px;
            min-height: 470px;
            color: ${colors.aqua};
            background: #eff9f0;
            border: 2px solid #ffffff;
            box-shadow: -50px -2000px 100px #ffffff, 30px 30px 80px rgba(55, 170, 73, 0.2),
                inset 10px 10px 20px rgba(255, 255, 255, 0.5);
            border-radius: 50px;
            h3 {
                font-size: 32px;
            }
            p {
                margin: 1em 0em;
                font-weight: 800;
                z-index: 200;
            }
            ul {
                padding-left: 1.75em;
                font-weight: 500;
            }
        }
    }
    .image-container {
    }
    .waves {
        z-index: -1;
        position: relative;
        top: 5em;
    }
    h2 {
        padding: 0.25em 0em;
        text-align: center;
        font-size: 32px;
        margin: 0 auto;
    }
    @media ${device.tablet} {
        /* .waves {
            z-index: -1;
            position: relative;
            top: 0em;
            margin: 0 auto;
            left: 4em;
        } */
        .waves {
            top: 0em;
        }
        .image-container {
            display: flex;
            justify-content: center;
        }
        .first-box {
            min-width: 684px;
        }
        .box-container {
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 9em;
        }
        .two {
            display: none;
        }
    }

    @media ${device.laptop} {
        .wellness-logo {
            display: block;
            position: absolute;
            bottom: 4em;
            width: 30%;
            right: 3em;
        }
        position: relative;
        height: 130vh;
        max-height: 1200px;
        min-height: 1100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .box-container {
            /* width: 80%; */
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;

            .first-box {
                min-height: 20px;
                min-width: 342px;
            }
        }

        /* .waves {
            z-index: -1;
            position: relative;
            top: -5em;
            left: 20em;
        } */
    }
    @media ${device.laptopL} {
        padding-top: 4em;
        h2 {
            padding: 0em;
            font-size: 48px;
        }
        .box-container {
            margin: 8em 0em 0em;
            .box {
                margin: 2em;
            }
        }

        .waves {
            z-index: -1;
            top: -8em;
        }
    }
    @media ${device.desktop} {
        height: 120vh;
    }
`;

export const Wellness: React.FC = () => {
    return (
        <>
            <StyledWellness>
                <h2 id="wellness">Wellness Plans</h2>
                <p style={{ textAlign: 'center', fontWeight: '600' }}>
                    <span style={{ fontSize: '24px', color: `${colors.mediumGreen}` }}>
                        * Sign Up Fee - $50 *
                    </span>
                    <br />
                    + $20 monthly fee
                    <br />
                    after initial signup
                </p>
                <div className="image-container">
                    <StaticImage
                        className="waves"
                        quality={100}
                        src="../images/wellness-waves.png"
                        placeholder="none"
                        alt="wavy green"
                    />
                </div>
                <div className="image-container">
                    <StaticImage
                        quality={100}
                        className="two waves"
                        src="../images/wellness-waves.png"
                        placeholder="none"
                        alt="wavy green"
                    />
                </div>

                <div className="box-container">
                    <div className="first-box box">
                        <h3 style={{ color: `${colors.mediumGreen}` }}>
                            Puppy/Kitten Wellness Plan:
                        </h3>
                        <ul>
                            <li>3 free exams (each normally cost 95) - 285 dollar value</li>
                            {/* <li>3 free exams (each normally cost 80) - 240 dollar value</li> */}
                            <li>
                                20% off every other exam for 12 months (until they are around 1 year
                                of age - unlimited number of exams) - exams would be 64 dollars
                            </li>
                            <li>
                                20% off all vaccines - vaccines cost 50 dollars so they now cost 40
                                dollars each{' '}
                            </li>
                            <li>
                                20% off of all fecal testing - each fecal cots 70 dollars so now it
                                costs 56 dollars
                            </li>
                            <li>
                                20% off on first year of prevention - Variable depending on what
                                product they purchase - this is an optional part of the plan, the
                                client can opt out of this (for cats mostly)
                            </li>
                        </ul>
                    </div>

                    <div className="box">
                        <h3 style={{ color: `${colors.mediumGreen}` }}>
                            Adult dog/cat wellness plan:
                        </h3>
                        <ul>
                            <li>2 free exams a year</li>
                            <li>20% off vaccines</li>
                            <li>20% off fecal testing</li>
                            <li>20% off 3 sets of diagnostics, blood work and/or x-rays</li>
                            <li>
                                20% yearly prevention - this is an optional part of the plan, the
                                client can opt out of this (for cats mostly)
                            </li>
                        </ul>
                    </div>
                    <div className="box">
                        <h3 style={{ color: `${colors.mediumGreen}` }}>
                            Senior Dog/Cat Wellness Plan:
                        </h3>
                        <ul>
                            <li>3 free exams a year</li>
                            <li>20% off vaccines</li>
                            <li>20% off fecal testing</li>
                            <li>20% off 5 sets of diagnostics, blood work and/or x-rays</li>
                            <li>
                                20% yearly prevention. this is an optional part of the plan, the
                                client can opt out of this (for cats mostly)
                            </li>
                        </ul>
                    </div>
                    <StaticImage
                        src="../images/icons/new-greenpoint-logo.svg"
                        placeholder="none"
                        className="wellness-logo"
                        alt="greenpoint logo"
                    />
                </div>
            </StyledWellness>
        </>
    );
};
