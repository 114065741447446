import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import check from '../images/icons/check.svg';
import greenWave from '../images/icons/green-wave.svg';
import { colors, device } from './layout/GlobalStyles';

const StyleHomelist = styled.section`
    padding: 4em 1em 0em;
    display: flex;
    flex-direction: column;

    align-items: center;
    p {
        font-weight: 600;
        margin: 1em 0em;
        line-height: 28px;
        letter-spacing: 0.5px;
    }
    ul {
        list-style: none;
        padding-left: 0em;
        li {
            display: flex;
            font-size: 16px;
            margin: 2em 0em;
            font-weight: 600;
            align-items: center;
            img {
                margin-right: 1em;
                width: 32px;
            }
        }
    }
    h2 {
        font-size: 36px;
        margin: 1em 0em 0.75em;
    }
    .image-container {
        position: relative;
        left: 1em;
    }
    .green {
        height: 100%;
        position: absolute;
        right: 1.5em;
        top: -1.5em;
        z-index: -1;
        background: #70c544;
        width: 100%;
    }
    .waiting-room {
        width: 300px;
    }
    @media ${device.laptop} {
        h2 {
            margin: 0em 0em 0.5em;
        }
        display: flex;
        flex-direction: row-reverse;
        padding: 6em 5em 0em;
        justify-content: space-between;
        .waiting-room {
            width: 425px;
            height: 651px;
        }
        .image-container {
            position: relative;
            left: 0em;
        }
        h2 {
            font-size: 52px;
            letter-spacing: 1px;
        }
        p {
            font-size: 18px;
            line-height: 38px;
            max-width: 550px;
        }
        ul {
            li {
                img {
                    width: 50px;
                }
                font-size: 18px;
            }
        }
    }
    @media ${device.laptopL} {
        display: flex;
        flex-direction: row-reverse;
        padding: 6em 7em 0em;
        max-width: 1300px;
        margin: 0 auto;
        justify-content: space-between;
        .waiting-room {
            width: 475px;
            height: 701px;
        }
        h2 {
            font-size: 52px;
            letter-spacing: 1px;
        }
        p {
            font-size: 21px;
            line-height: 34px;
            max-width: 550px;
        }
        ul {
            li {
                margin: 1.8em 0em;
                img {
                    width: 50px;
                }
                font-size: 18px;
            }
        }
    }
    @media ${device.desktop} {
        max-width: 1500px;
        padding: 5em 6em 0em;
        margin: 0em auto;
        .waiting-room {
            width: 535px;
            height: 700px;
        }
        h2 {
            font-size: 58px;
            letter-spacing: 1px;
        }
        p {
            font-size: 18px;
            line-height: 36px;
            max-width: 600px;
        }
        ul {
            li {
                img {
                    width: 55px;
                }
                font-size: 23px;
            }
        }
    }
`;

export const HomeList: React.FC = () => {
    return (
        <>
            <StyleHomelist>
                <div className="image-container">
                    <div className="green"></div>
                    <StaticImage
                        placeholder="none"
                        className="waiting-room"
                        src="../images/modern-waiting-room.jpeg"
                        alt="A kitten"
                    />
                </div>
                <div>
                    <h2>OUR PROMISE!</h2>
                    <p>
                        Your pet deserves the best and we believe so do you. You are afterall a
                        team. At Greenpoint Veterinary Care, we provide an experience for both your
                        pet and you.
                    </p>
                    <ul>
                        <li>
                            <img src={check} alt="check mark" />
                            Easy Bookings
                        </li>
                        <li>
                            <img src={check} alt="check marl" />
                            Clear Cost
                        </li>
                        <li>
                            <img src={check} alt="check marl" />
                            Friendly Modern Environment
                        </li>
                        <li>
                            <img src={check} alt="check marl" />
                            Convenient Location
                        </li>
                        <li>
                            <img src={check} alt="check marl" />
                            Exceptional Care
                        </li>
                    </ul>
                </div>
            </StyleHomelist>
            <img
                style={{
                    position: 'relative',
                    top: '.5em',
                    transform: 'scaleY(-1)',
                    width: '100vw',
                    height: 'auto',
                }}
                className="wave"
                src={greenWave}
                alt="A green wave"
            />
        </>
    );
};
