import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHomeSlider = styled.section`
    overflow-x: scroll;
    overflow-y: auto;
    .images {
        width: 1000px;
        display: flex;
    }

    img {
        width: 300px;
    }
    @media ${device.laptopL} {
        .images {
            width: 100vw;
            background: ${colors.lightGreen};
        }
        img {
            padding: 0.2em;
            width: 20vw;
        }
    }
`;

export const HomeSlider: React.FC = () => {
    return (
        <StyledHomeSlider>
            <div className="images">
                <StaticImage
                    placeholder="none"
                    src="../images/blueprint/green-wall.jpg"
                    alt="green wall"
                />
                <StaticImage
                    placeholder="none"
                    src="../images/blueprint/waiting-room.jpg"
                    alt="waiting room blueprint"
                />
                <StaticImage
                    placeholder="none"
                    src="../images/blueprint/reception.jpg"
                    alt="reception desk and waiting room"
                />
                <StaticImage
                    placeholder="none"
                    src="../images/image-1.jpeg"
                    alt="reception desk from front angle"
                />
                <StaticImage
                    placeholder="none"
                    src="../images/image-2.jpeg"
                    alt="reception desk from front angle"
                />
            </div>
        </StyledHomeSlider>
    );
};
