import styled from '@emotion/styled';
import React from 'react';
import { Button } from './StyledComponents';
import { colors, device } from './GlobalStyles';
import wave from '../../images/icons/green-wave.svg';

const StyledMap = styled.div`
    margin: 0em 0em;
    position: relative;
    background: ${colors.lightGreen};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em 0em 3.5em;
    button {
        cursor: pointer;
    }
    button {
        margin: 0em 1em 0em;
    }
    div {
        h2 {
            margin: 1em 0.5em;
        }
        p {
            margin: 0em 0.75em 2.5em;
        }
    }
    .right-column {
        display: flex;
        flex-direction: column;
    }
    iframe {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        border-radius: 40px;
        height: 430px;
        width: 340px;
    }
    p {
        padding-left: 0.5em;
    }
    h2 {
        padding-left: 0.25em;
    }

    @media ${device.tablet} {
        p {
            font-size: 18px;
            padding-left: 0.25em;
        }
        h2 {
            font-size: 32px;
            padding-left: 0em;
        }
        flex-direction: row;
        justify-content: center;
        width: 100vw;
        iframe {
            height: 600px;
            width: 506px;
        }
        .right-column {
            margin-left: 4em;
            display: flex;
            width: 40vw;
            align-items: flex-start;
            flex-direction: column;
        }
    }
    @media ${device.laptopL} {
        .right-column {
            p {
                font-size: 18px;
                width: 80%;
            }
            h2 {
                margin: 1em 0.2em;
                font-size: 48px;
                line-height: 66px;
            }
        }
        iframe {
            height: 600px;
            width: 526px;
        }
    }
    @media ${device.laptopL} {
        padding-top: 6em;
        .right-column {
            margin-left: 6em;
            p {
                font-size: 22px;
                width: 80%;
            }
            h2 {
                margin: 1em 0.2em;
                font-size: 54px;
                line-height: 70px;
            }
        }
        iframe {
            height: 680px;
            width: 546px;
        }
    }
`;

const StyledButton = styled(Button)``;

export const Map: React.FC = () => {
    return (
        <>
            <StyledMap>
                <div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.4736829970775!2d-73.95357088500492!3d40.72960144447631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259470d530bf7%3A0x73903b23ece7cad6!2s211%20McGuinness%20Blvd%2C%20Brooklyn%2C%20NY%2011222!5e0!3m2!1sen!2sus!4v1642434645873!5m2!1sen!2sus"
                        width="100%"
                        loading="lazy"
                        title="greenpoint vet location"
                        height="100%"
                        frameBorder="0"
                        style={{ border: 0 }}
                        aria-hidden="false"
                    />
                </div>
                <div className="right-column">
                    <h2>Veternarian in Greenpoint, Brooklyn, NY</h2>
                    <p>
                        We are conveniently located at the Otto building in Suite 6. Right at the
                        heart of Greenpoint Brooklyn.{' '}
                    </p>
                    <a
                        rel="noreferrer"
                        href="https://goo.gl/maps/wNWzAtYSLuEaJSz2A"
                        target="__blank"
                    >
                        <StyledButton wide>Directions</StyledButton>
                    </a>
                </div>
            </StyledMap>
            <img
                style={{ zIndex: '2', position: 'relative', background: 'none', width: '100vw' }}
                src={wave}
                alt="green wave"
            />
        </>
    );
};
