import * as React from 'react';
import { graphql } from 'gatsby';
//components
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { colors, device } from '../components/layout/GlobalStyles';
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Template } from '../components/layout/StyledComponents';
import { Hero } from '../components/Hero';
import { HomeServices } from '../components/HomeServices';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '../components/Seo';

const StyledTemplate = styled(Template)`
    h1 {
        font-size: 36px;
    }
    .section {
        padding: 0px 0 72px;
    }
    @media ${device.tablet} {
        h1 {
            font-size: 48px;
        }
    }
    @media ${device.laptop} {
        .section {
            padding: 0px 0 127px;
        }
    }
`;

interface Props {
    data: {
        mdx: {
            frontmatter: {
                title: string;
                heroTitle: string;
                seoTitle: string;
                seoDescription: string;
                altTag: string;
                image: IGatsbyImageData;
            };
            body: string;
        };
    };
}

const GrievingAPet: React.FC<Props> = () => {
    return (
        <>
            <DefaultLayout>
                <Seo
                    title="Grieving A Pet - Greenpoint BK, Vet Care"
                    description="We often have pets for their whole life, we are their whole world. We share space with them, life transitions, heartbreaks, celebrations, and more and they are there."
                />
                <Hero white title="Grieving a Pet" />

                <StyledTemplate>
                    <span>Written By Stephanie West</span>
                    <div className="image-container">
                        <>
                            <StaticImage
                                imgStyle={{ borderRadius: '33px' }}
                                className="bottom-layer"
                                src="../images/grieving-a-pet.jpg"
                                alt="grieving a pet backdrop"
                            />
                            <StaticImage
                                imgStyle={{ borderRadius: '33px' }}
                                className="featured-image"
                                src="../images/grieving-a-pet.jpg"
                                alt="grieving a pet"
                            />
                        </>
                    </div>
                    <div className="section">
                        <p>
                            We often have pets for their whole life, we are their whole world. We
                            share space with them, life transitions, heartbreaks, celebrations, and
                            more and they are there. They can often represent a time in a person’s
                            life, and that when that pet dies, it’s like that version of that person
                            died. When comforting someone who has lost a pet, it might be helpful to
                            remember that this pet companioned them at times when maybe no other
                            human was present. There may have been literally no one else to share
                            silent space with. Think about that. When was the last time a close
                            friend or family member sat with you in complete silence without either
                            one of you feeling awkward or immediately filling that silence?
                        </p>
                        <p>
                            As a therapist, I’ve seen lots of people begin treatment because of some
                            kind of loss they’ve recently experienced. Grief is so individualized
                            and, when this loss is experienced, can bring up all sorts of unresolved
                            losses in that person’s life. When a parent or a sibling or a friend
                            dies, there are social norms that are often accepted and understood by
                            those in the individual’s life.
                        </p>
                        <p>
                            Sometimes when a pet dies, not everyone understands. “It was just an
                            animal, what’s the problem?!” is something that often leaves the person
                            feeling dismissed and even more alone. Some people feel safer with their
                            pets than they do with people, so in a way, dismissing their grief in
                            this way can feel very destabilizing and emotionally unsafe.
                        </p>
                        <p>
                            When someone loses a pet, it’s still a grieving process that follows, of
                            course. But there are some pet-specific things to consider in order to
                            help yourself or someone else going through this experience.
                        </p>
                        <h2>Reach out to someone who has lost a pet</h2>
                        <p>
                            Hearing from someone else who has gone through losing a pet can help to
                            validate the grief you are experiencing. If your grief has been
                            invalidated by your usual supporters, seek out others online or among
                            acquaintances. Ask them to tell you about their pet and their process.
                            Your curiosity will be healing for you both.
                        </p>
                        <h2>Create a legacy</h2>
                        <p>
                            Some people find it very helpful to create something in the name of
                            their pet. I personally donate to{' '}
                            <a
                                href="https://bestfriends.org/donate/pet-memorials"
                                rel="noopener noreferrer"
                                target="__blank"
                            >
                                Best Friends Animal Society
                            </a>
                            in the name of a friend or family’s recently lost pet as a way to let
                            them know that I’m thinking about them and their pet. Some like to plant
                            a tree, have a headstone made, display the pet’s ashes in the home, and
                            other things that remind them of their love for the animal. If it brings
                            you comfort, do it.
                        </p>
                        <h2>Find self-care in your new routine</h2>
                        <p>
                            Sometimes we don’t realize how ritualized our daily lives can be when we
                            have pets. Pets feel comforted by their routine, it makes them feel
                            safe. It turns out that it does something for us as well. As you adjust
                            to your new schedule without the feedings, administering medications,
                            and walks, be gentle with yourself if sadness seems to come out of
                            nowhere. There may also be some relief in not having to complete those
                            tasks anymore, and guilt will sometimes creep up. This is totally normal
                            and a part of the grieving process! It can be helpful to replace these
                            routines with new ones like taking a walk, reading a book, or taking a
                            bath. If nothing else, honor (out loud) that at this time of day would
                            be when you would take your dog for a walk, for example.
                        </p>
                        <h2>Seek professional help</h2>
                        <p>
                            If your grief is lingering and interfering with your every day life, it
                            can be helpful to reach out to a professional. Not only can we assess
                            for things like depression and anxiety, we can also help you talk
                            through some other experiences that this current loss has “woken up”
                            inside of you that are sometimes other forms of unprocessed grief.
                        </p>
                    </div>
                </StyledTemplate>
            </DefaultLayout>
        </>
    );
};

export default GrievingAPet;
