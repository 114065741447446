import { css, Global } from '@emotion/react';
import React from 'react';
import calender from '../../images/icons/calender.svg';

export const colors = {
    grey: '#171B24',
    black: '#14142B',
    white: '#F7F7FC',
    navy: '#042950',
    darkGreen: '#219653',
    textGreen: '#1A822B',
    mediumGreen: '#70C544',
    lightGreen: '#C8F9CC',
    extraLightGreen: '#EFF9F0',
    aqua: '#1693A5',

    teal: '#37A3A5',
    gradient: 'linear-gradient(321.57deg, #021417 0%, #14C8A7 144.15%)',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '768px',
    laptop: '1020px',
    laptopL: '1280px',
    desktop: '1540px',
    desktopL: '1920px',
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktopL})`,
};

export const GlobalStyles: React.FC = () => {
    return (
        <Global
            styles={css`
                html {
                    box-sizing: border-box;
                    font-size: 100%;
                    overflow-x: hidden;
                    -ms-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    scroll-behavior: smooth;
                    button {
                        cursor: pointer;
                    }
                }

                .shadow {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 37px 0px,
                        rgba(0, 0, 0, 0.3) 0px 8px 29px -20px;
                }
                a {
                    font-weight: 600;
                    letter-spacing: 0.75px;
                    text-decoration: none;
                    color: ${colors.navy};
                }

                input[type='date']::-webkit-calendar-picker-indicator {
                    position: absolute;
                    left: -1em;

                    fill: red;
                    top: 1em;
                    height: 100%;
                    width: 30px;
                    background: url(${calender}) no-repeat;
                    -webkit-appearance: none;
                }
                body {
                    background: ${colors.white};
                    font-weight: normal;
                    font-size: 1rem;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    margin: 0;
                    -webkit-font-smoothing: antialiased;
                }
                * {
                    margin: 0;
                    font-family: 'Poppins';
                    transition: 0.2s all;
                }
                .container {
                    max-width: 1200px;
                    margin: 0 auto;
                }
                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }
                input:focus,
                textarea:focus {
                    outline: 2px solid ${colors.darkGreen}; /* oranges! yey */
                    border: none;
                }
            `}
        />
    );
};
