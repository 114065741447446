import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import email from '../images/icons/email.svg';
import bg from '../images/tiled-bg-pattern.png';

interface Props {
    image: string;
}

const ExtraButton = styled(Button)`
    font-size: 10px;
    width: 120px;
    margin: 1em 0em;
    @media ${device.tablet} {
        font-size: 18px;
        .navy {
            margin-left: 2em;
        }
        margin: 1em 0em;
        width: 220px;
    }
`;

const StyledGatsbyImage = styled(GatsbyImage)<Props>`
    width: 62.5vw;
    height: 75vh;
    margin-bottom: 0.5em;
    background-size: contain;
    @media ${device.laptop} {
        width: 50.5vw;
        border-radius: 0px;
        height: 100vh;
    }
    @media ${device.laptopL} {
        width: 62.5vw;
    }
`;

const StyledHomeHero = styled.div`
    background: url(${bg});
    .email-input {
        position: relative;
        .email {
            position: absolute;
            top: 1em;
        }
    }
    form {
        padding-left: 1em;
        display: flex;
        input {
            width: 200px;
            margin-right: 1em;
            padding: 1em;
            border: none;
            background: ${colors.white};
            border-radius: 15px;
        }

        div {
            position: relative;
            img {
                position: absolute;
                left: 0.4em;
                top: 0.4em;
            }
        }
    }
    .columns {
        justify-content: space-between;
        width: 100vw;
        display: flex;
    }
    .left-column {
        z-index: 2000;
        margin-top: 13vh;
        padding-left: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            display: none;
        }
        img {
            width: 120px;
        }
        p {
            width: 75%;
            margin-top: 0.5em;
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            color: ${colors.mediumGreen};
        }
    }
    .hero-buttons {
        z-index: 800;
        margin-top: 4.3em;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .mobile-text {
        padding-left: 1em;
        padding-right: 2.5em;
        margin-bottom: 1em;
        h2 {
            font-size: 36px;
            line-height: 48px;
            letter-spacing: 0.1px;
            color: ${colors.mediumGreen};
        }
        p {
            color: ${colors.navy};
            font-weight: 600;
            letter-spacing: 0.75px;
            margin-top: 0.5em;
            font-size: 16px;
        }
    }
    .desktop-form {
        display: none;
    }
    .mobile-form {
        img {
            position: absolute;
            top: 1em;
            left: 0.5em;
        }
        input {
            position: relative;
            padding-left: 3em;
        }
        display: flex;
    }
    span {
        display: none;
    }
    .bubble-desktop {
        display: none;
    }
    padding: 0em 0em 2em;
    .right-column {
        position: relative;
    }
    .bubble-mobile {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0.75em 1em;
        img {
            width: 70%;
        }
        p {
            margin-left: 0em;
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
            letter-spacing: 0.75px;
        }

        position: absolute;
        z-index: 1;
        background: white;
        backdrop-filter: blur(4px);
        border-radius: 32px 32px 32px 0px;
        bottom: 6em;
        left: 2em;
    }
    @media ${device.tablet} {
        .left-column {
            z-index: 3;
            .hero-buttons {
                margin-top: 0em;
                display: flex;
                width: 200px;
            }
            img {
                width: 240px;
            }
        }
    }
    @media ${device.laptop} {
        .hero-buttons {
            flex-direction: row;
            width: 180px;
            button {
                width: 200px;
            }
            .navy {
                margin-left: 1em;
            }
        }
        .bubble-mobile {
            display: none;
        }
        .right-column {
            position: relative;
        }
        .bubble-desktop {
            display: flex;
            justify-content: space-around;
            padding: 0.75em 0.75em;
            align-items: center;
            img {
                margin-right: 0em;
                height: 30px;
            }
            p {
                margin-left: 1em;
                font-size: 16px;
                line-height: 38px;
                /* or 211% */
                font-weight: 600;
                letter-spacing: 0.75px;
            }
            position: absolute;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(4px);
            border-radius: 32px 32px 32px 0px;
            bottom: 5em;
            left: 3em;
            z-index: 200;
        }
        span {
            display: block;
            font-weight: 600;
            margin: 0.5em 0em 1.5em;
            font-size: 21px;
            letter-spacing: 0.75px;
        }
        .mobile-text {
            display: none;
        }
        .mobile-form {
            display: none;
        }
        .desktop-form {
            align-items: center;
            padding-left: 0em;
            display: flex;
        }

        .left-column {
            img {
                width: 260px;
            }
            padding: 0em 2.5em;
            form {
                input {
                    padding: 1.2em;

                    color: grey;
                }
                div {
                    img {
                        width: 30px;
                        top: 0.75em;
                        left: 0.75em;
                    }
                }
            }
            input {
                width: 300px;
                text-indent: 40px;
            }
            align-items: flex-start;
            h2 {
                display: block;
                font-size: 59px;
            }
            p {
                font-size: 36px;

                text-align: left;
                margin: 0.5em 0em 0.2em;
                width: 100%;
            }
        }
    }
    @media ${device.laptopL} {
        .hero-buttons {
            flex-direction: row;
            width: 180px;
            button {
                width: 220px;
            }
        }
        .left-column {
            img {
                width: 240px;
            }
            span {
                margin: 0.5em 0em 0em;
            }
            h2 {
                font-size: 48px;
                line-height: 58px;
            }
            p {
                margin: 0.25em 0em;
            }
        }
        .bubble-desktop {
            padding: 1.5em 1.5em;
            margin-bottom: 5em;
            align-items: center;
            img {
                margin-right: 1em;
                height: 50px;
            }

            p {
                margin-left: 1em;
                font-size: 18px;
                line-height: 38px;
                letter-spacing: 0.75px;
            }
        }
    }
    @media ${device.desktop} {
        .bubble-desktop {
            padding: 1.5em 0em;

            margin-bottom: 5em;
            align-items: center;
            p {
                width: 75%;
                margin-left: 0em;
                font-size: 24px;
                line-height: 42px;
                letter-spacing: 0.75px;
            }
        }
        .left-column {
            img {
                padding-top: 1em;
                width: 300px;
            }
            padding-left: 4em;
        }

        h2 {
            max-width: 600px;
            font-size: 68px;
            letter-spacing: 1px;
        }
    }
    p {
        font-size: 42px;
    }
    span {
        font-size: 30px;
    }
    @media ${device.desktopL} {
        .bubble-desktop {
            margin-bottom: 5em;
        }
        .left-column {
            h2 {
                font-size: 68px;
                line-height: 84px;
                margin: 0em 0em 0.2em;
            }
            padding-left: 6em;
        }
    }
`;

export const HomeHero: React.FC = () => {
    const { desktopImage } = useStaticQuery(graphql`
        query {
            desktopImage: file(relativePath: { eq: "desktop-skyline.jpeg" }) {
                id
                childImageSharp {
                    gatsbyImageData(quality: 90, webpOptions: { quality: 90 })
                }
            }
        }
    `);

    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');
        const data = Object.fromEntries(new FormData(e.target));
        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        setSubmitState('submitted');
        e.target.reset();
    };

    const image = getImage(desktopImage);

    return (
        <>
            <StyledHomeHero>
                <div className="columns">
                    <div className="left-column">
                        <StaticImage
                            placeholder="none"
                            src="../images/greenpoint-logo-icon.svg"
                            alt="greenpoint vet logo"
                        />

                        <h2 style={{ marginTop: '40px' }}>A Vet for you & your pet.</h2>
                        <span>Sign up for updates!</span>
                        {/* {submitState === 'submitted' ? (
                            <>
                                <h3
                                    style={{
                                        position: 'relative',
                                        top: '.2em',
                                        color: `${colors.mediumGreen}`,
                                    }}
                                >
                                    Thanks! We will be in touch!
                                </h3>
                                <br />
                                <br />
                                <br />
                                <br />
                            </>
                        ) : (
                            <form onSubmit={onSubmit} className="desktop-form">
                                <div className="email-input">
                                    <input
                                        name="emailSignup"
                                        placeholder="Enter e-mail"
                                        className="shadow"
                                        type="email"
                                    />
                                    <img className="email" src={email} alt="email icon" />
                                </div>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="mailto:Info@greenpointvetcare.com"
                                >
                                    <Button type="submit">Sign Up!</Button>
                                </a>
                            </form>
                        )} */}
                        <div className="hero-buttons">
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://greenpointveterinarycare.koala.health/"
                            >
                                <ExtraButton>Online Pharmacy</ExtraButton>
                            </a>
                            <a
                                target="_blank"
                                href="https://greenpointvet.use2.ezyvet.com/external/portal/main/login"
                                rel="noreferrer"
                            >
                                <ExtraButton
                                    className="navy"
                                    style={{
                                        background: `${colors.navy}`,
                                    }}
                                >
                                    Book Appointment
                                </ExtraButton>
                            </a>
                        </div>
                    </div>

                    <div className="right-column">
                        <a
                            rel="noreferrer"
                            target="__blank"
                            href="https://goo.gl/maps/xiDE8LNB2HtJygKu9"
                        >
                            <div className="bubble-desktop ">
                                <StaticImage
                                    placeholder="none"
                                    src="../images/icons/map-marker.svg"
                                    alt="marker icon"
                                />
                                <p>
                                    The Otto Building, 211 McGuinness Blvd, <br />
                                    Suite 6, Brooklyn, NY 11222
                                </p>
                            </div>
                        </a>
                        <div className="bubble-mobile">
                            <StaticImage
                                placeholder="none"
                                src="../images/icons/map-marker.svg"
                                alt="marker icon"
                            />
                            <p>
                                The Otto Building,
                                <br /> 211 McGuinness
                                <br /> Blvd, Suite 6,
                                <br />
                                Brooklyn, NY 11222
                            </p>
                        </div>
                        <StyledGatsbyImage
                            imgStyle={{ borderRadius: '0px 0px 0px 40px' }}
                            placeholder="none"
                            alt="city skyline"
                            image={image}
                        />
                    </div>
                </div>
                <div className="mobile-text">
                    <h2>A Vet for you & your pet.</h2>
                    <p>A vet experience that keeps you and your best. Sign up for updates!</p>
                </div>
                <form className="mobile-form">
                    <div>
                        <input
                            required
                            name="email"
                            placeholder="e-mail"
                            className="shadow"
                            type="email"
                        />
                        <img src={email} alt="email icon" />
                    </div>
                    <a target="_blank" href="mailto:Info@greenpointvetcare.com" rel="noreferrer">
                        <Button>Sign Up!</Button>
                    </a>
                </form>
            </StyledHomeHero>
        </>
    );
};
