import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors, device } from './layout/GlobalStyles';

interface Props {
    white?: boolean;
}

const StyledHero = styled.div<Props>`
    background: ${({ white }) => (white ? `${colors.white}` : `${colors.lightGreen}`)};
    .filler {
        background: ${colors.white};
        height: 6.5em;
    }

    ul {
        list-style: none;
        display: flex;
        padding: 1em 0em 1em 0em;
        margin-left: 0em;
        justify-content: center;
        .ok {
            font-weight: 400;
            border-right: 2px solid ${colors.aqua};
        }

        li {
            height: 25px;
            padding: 0em 1em;
        }
    }

    h1 {
        padding-top: 0.2em;
        font-size: 36px;
        line-height: 48px;
        color: ${colors.mediumGreen};
        text-align: center;
    }
    @media ${device.laptop} {
        position: relative;

        .filler {
            background: ${colors.white};
            height: 10.5em;
        }
        h1 {
            text-align: left;
        }
        display: flex;
        ul {
            position: absolute;
            right: 0em;
            li {
                cursor: Pointer;
                color: ${colors.aqua};
                font-weight: 600;
                &:hover {
                    color: #57cc98;
                }
            }
        }
        align-items: flex-end;
        background: ${colors.white};
        max-width: 1000px;
        margin: 0 auto;
    }
    @media ${device.laptopL} {
        max-width: 1280px;
        padding: 6em 4em 0em;
        h1 {
            font-size: 59px;
            width: 50%;
            line-height: 66px;
        }
    }
    @media ${device.desktop} {
        padding: 7em 4em 0em;
        margin-bottom: 4em;
        max-width: 1480px;
        h1 {
            font-size: 72px;
            line-height: 84px;
        }
    }
    @media ${device.desktopL} {
        max-width: 1440px;
        padding-top: 10em;
    }
`;

interface Props {
    title: string;
}

export const Hero: React.FC<Props> = ({ white, title }) => {
    return (
        <StyledHero white={white}>
            <div className="filler"></div>
            <h1>{title}</h1>
            <ul>
                <Link to="/">
                    <li className="ok">Home</li>
                </Link>
                {title === 'Services' && (
                    <Link to="/services/">
                        <li style={{ color: `${colors.aqua}` }}>Services</li>
                    </Link>
                )}
                {title !== 'Services' && title !== 'About Us' && title !== 'Contact Us' && (
                    <>
                        <Link to="/services/">
                            <li className="ok">Services</li>
                        </Link>
                        <li style={{ color: `${colors.aqua}` }}>{title}</li>
                    </>
                )}

                {title === 'About Us' && (
                    <Link to="/about-us/">
                        <li style={{ color: `${colors.aqua}` }}>About Us</li>
                    </Link>
                )}

                {title === 'Contact Us' && (
                    <Link to="/contact/">
                        <li style={{ color: `${colors.aqua}` }}>Contact Us</li>
                    </Link>
                )}
            </ul>
        </StyledHero>
    );
};
